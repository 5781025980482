import React from "react";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import useStyles from "./style"

const VisibilityIconFilter = (props) => {
    const classes = useStyles();
    return (
      <Tooltip title={props.label}>
        <IconButton
            className={classes.icon}
            onClick={props.handleShowAll}
        >
          {props.showAll ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </Tooltip>) };
export default VisibilityIconFilter;
