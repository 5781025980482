import {
    adminMicroserviceEntity, alertEntity, analyticsEntity, auditEntity,
    automationEntity,
    automationsEntity, currentUser, divisionsEntity, exceptionsEntity, exportEntity, financialEntity, jsonEntity,
    groupEntity,
    groupsEntity, guacamole, logs,
    orchestratorEntity, permissionEntity, resourcesEntity, routeApi, shared, srqueuesEntity, tagsEntity, userEntity
} from "util/configs/backendEntities";

export const URL_PROCESSES = `/${routeApi}/${orchestratorEntity}/${automationEntity}/`;
export const URL_BASE_EXPORT = `/${routeApi}/export/`;
export const URL_PROCESSES_KPI = `/${routeApi}/${orchestratorEntity}/${automationEntity}/kpi`;
export const URL_RPAQUAL_PROCESSES = `/${routeApi}/rpaqual/${automationsEntity}`;
export const URL_SCHEDULE = `/${routeApi}/${orchestratorEntity}/schedule/`;
export const URL_LINKS = `/${routeApi}/${orchestratorEntity}/linked_documentation/`;
export const URL_PROCESS_EXECUTION = `/${routeApi}/${orchestratorEntity}/process-execution/`;
export const URL_WORK_QUEUE_ITEM = `/${routeApi}/${orchestratorEntity}/work_queue_item/`;
export const URL_CURRENT_USER = `/${currentUser}`;
export const URL_UPDATE_PASSWORD = `/${routeApi}/${adminMicroserviceEntity}/${userEntity}/userPassword`;
export const URL_UPDATE_DEV_COST = `${URL_RPAQUAL_PROCESSES}/devCost`;
export const URL_ORS = `/${routeApi}/${orchestratorEntity}/ors/`;
export const URL_GUACAMOLE = `/${routeApi}/${guacamole}`;
export const URL_ORCHESTRATOR = `/${routeApi}/${orchestratorEntity}/management`;
export const URL_USER = `/${routeApi}/${adminMicroserviceEntity}/${userEntity}`;
export const URL_USER_PERMISSIONS = `/${routeApi}/${adminMicroserviceEntity}/${userEntity}/${permissionEntity}`;
export const URL_FLEET = `/${routeApi}/${adminMicroserviceEntity}/${groupEntity}`;
export const URL_FLEET_v2 = `/${routeApi}/${orchestratorEntity}/${groupsEntity}`;
export const URL_DIVISION = `/${routeApi}/${orchestratorEntity}/${divisionsEntity}`;
export const URL_DIVISION_ADMIN = `/${routeApi}/${adminMicroserviceEntity}/${divisionsEntity}`;
export const URL_RPA_QUAL = `/${routeApi}/rpaqual`;
export const URL_RPA_QUAL_PROCESSES = `${URL_RPA_QUAL}/${automationsEntity}`;
export const URL_RPA_QUAL_PROCESSES_ARCHIVE = `${URL_RPA_QUAL_PROCESSES}/archive`;
export const URL_RPA_QUAL_EXPORT = `${URL_RPA_QUAL}/${exportEntity}`;
export const URL_PROCESSES_ANALYSIS = `/${routeApi}/${orchestratorEntity}/${automationEntity}/analysis`;
export const URL_DEFAULT_ALERT = `/${routeApi}/${alertEntity}/default-${alertEntity}/`;
export const URL_CUSTOM_ALERT = `/${routeApi}/${alertEntity}/custom-${alertEntity}/`;
export const URL_ROBOT = `/${routeApi}/${orchestratorEntity}/${resourcesEntity}/`;
export const URL_BP_CONNECTOR = `/${routeApi}/bpconnector/`;
export const URL_UIPATH_CONNECTOR = `/${routeApi}/uipathconnector/`;
export const URL_SYNCHRO = `/${routeApi}/synchro/`;
export const URL_COST_ALLOCATIONS = `/${routeApi}/${orchestratorEntity}/${financialEntity}/`;
export const URL_COST_SHARED = `/${routeApi}/${orchestratorEntity}/${shared}/`;
export const URL_COST_SHARED_LIST = `/${routeApi}/${orchestratorEntity}/${shared}/`;
export const URL_ANALYTICS = `/${routeApi}/${orchestratorEntity}/${analyticsEntity}/`;
export const URL_TAG = `/${routeApi}/${orchestratorEntity}/${tagsEntity}`;
export const URL_TAG_ADMIN = `${routeApi}/${orchestratorEntity}/${tagsEntity}`;

export const URL_COST_SHARED_ALL = `/${routeApi}/${orchestratorEntity}/${shared}/all/`;
export const URL_PROCESSES_DATA_MAPPING = `${URL_PROCESSES}data-mappings`;
export const URL_EXCEPTION_WORKFLOW = `/${routeApi}/${orchestratorEntity}/exceptionWorkflow`;
export const URL_AUTOMATION_EXCEPTION_WORKFLOW = `/${routeApi}/${orchestratorEntity}/executionWorkflow`;
export const URL_EXCEPTION = `/${routeApi}/${orchestratorEntity}/${exceptionsEntity}/`;
export const URL_LICENSES = `/${routeApi}/${adminMicroserviceEntity}/licenses`;
export const URL_SR_QUEUES = `/${routeApi}/${srqueuesEntity}`;
export const URL_SR_QUEUES_MNGT = `/${routeApi}/${srqueuesEntity}/queue`;
export const URL_TAGS = `/${routeApi}/${orchestratorEntity}/${tagsEntity}`;
export const URL_STEPPER_LOAD_STEPS = `${URL_RPA_QUAL}/params/steps`;
export const URL_PROCESS_UPDATED = `${URL_RPA_QUAL_PROCESSES}/update`;
export const URL_PROCESS_CALCULATE_FACTOR = `${URL_RPA_QUAL}/calculate`;
export const URL_DASHBOARD = `/${routeApi}/${orchestratorEntity}/dashboard/`;
export const URL_AUDIT_LOGS = `/${routeApi}/${auditEntity}/${logs}`;
export const URL_AUDIT_ACTIONS = `/${routeApi}/${auditEntity}/${logs}/actions`;
export const URL_AUDIT_USERS = `/${routeApi}/${auditEntity}/${logs}/users`;
export const URL_AUDIT_MODULES = `/${routeApi}/${auditEntity}/${logs}/modules`;
export const URL_ROLES = `/${routeApi}/${adminMicroserviceEntity}/roles`;
export const URL_ORCHESTRATIONS = `/${routeApi}/${srqueuesEntity}/orchestration`;
export const URL_INFRASTRUCTURE_ORCHESTRATION = `/${routeApi}/${orchestratorEntity}/infrastructure-orchestration`;
export const URL_RESOURCE_CREDENTIALS = `/${routeApi}/${orchestratorEntity}/resource-credential`;
export const URL_GENERATION = `${routeApi}/${srqueuesEntity}/${jsonEntity}`;
