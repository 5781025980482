import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { CustomTextField } from "components/FormFields/CustomAutoComplete";
import CustomButton from "components/CustomButton";
import { updateCurrentUserPassword } from "redux/actions/services";
import useStyles from "../style";

export default function PasswordReset() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isPasswordRequestLoading, setIsPasswordRequestLoading] = React.useState(false);
  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState(true);
  const {
    formState: { errors },
    handleSubmit,
    getValues,
    register,
      trigger,
      watch
  } = useForm({
    defaultValues: {
      currentEmail: "",
      confirmedEmail: "",
    },
  });

  const onUpdatePasswordSuccess = () => {
    toast.success(t("profile.update.password.success"))
    setIsCurrentPasswordValid(false)
    setIsPasswordRequestLoading(false);
  }

  const onUpdatePasswordError = (error) => {
    if (error?.response?.data?.title === "profile.update.password.wrong.current.password") {
      setIsCurrentPasswordValid(false);
      toast.error(t(error?.response?.data?.title))
      trigger("currentPassword")
    }
    else toast.error(t("Unexpected Error"))
    setIsPasswordRequestLoading(false);
  }

  const handleFormSubmit = () => {
    setIsPasswordRequestLoading(true);
    dispatch(
        updateCurrentUserPassword(
        {
          currentPassword: getValues("currentPassword"),
          newPassword: getValues("newPassword"),
          confirmation: getValues("confirmedPassword")
        },
        onUpdatePasswordSuccess,
        onUpdatePasswordError
      )
    )
  };

  useEffect(() => {
    if (!isCurrentPasswordValid) setIsCurrentPasswordValid(true)
  }, [watch("currentPassword")])

  return (
    <Box
      className={classes.profileSection}
      height="100%"
      component="form"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Typography className={classes.profileSectionTitle}>
        {t("change.your.password")}
      </Typography>
      <Box>
        <CustomTextField
          {...register("currentPassword", {
            required: {
              value: true,
              message: t("user.management.formControl.required"),
            },
              validate: () => (isCurrentPasswordValid
            ? true
            : t("profile.update.password.wrong.current.password")),
          })}
          valued={getValues("currentPassword")}
          variant="standard"
          fullWidth
          size="small"
          type="password"
          label={t("profile.update-password.current-password")}
          className={classes.textField}
          error={!!errors?.currentPassword}
          helperText={!!errors?.currentPassword && errors?.currentPassword?.message}
        />
        <CustomTextField
          {...register("newPassword", {
            required: {
              value: true,
              message: t("user.management.formControl.required"),
            },
          })}
          valued={getValues("newPassword")}
          variant="standard"
          fullWidth
          size="small"
          type="password"
          label={t("profile.update-password.new-password")}
          className={classes.textField}
          error={!!errors?.newPassword}
          helperText={!!errors?.newPassword && errors?.newPassword?.message}
        />
        <CustomTextField
          {...register("confirmedPassword", {
            required: {
              value: true,
              message: t("user.management.formControl.required"),
            },
            validate: (value) => (value === getValues("newPassword")
                ? true
                : t("profile.update-password.wrong.password")),
          })}
          valued={getValues("confirmedPassword")}
          onChange={(e) => e}
          variant="standard"
          fullWidth
          size="small"
          type="password"
          label={t("profile.update-password.confirm-password")}
          className={classes.textField}
          error={!!errors.confirmedPassword}
          helperText={
            !!errors.confirmedPassword && errors?.confirmedPassword?.message
          }
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginTop="30px"
      >
        <CustomButton
          view="primary"
          disabled={isPasswordRequestLoading}
          size="small"
          type="submit"
          startIcon={
            isPasswordRequestLoading && (
              <CircularProgress size={15} color="inherit" />
            )
          }
        >
          {t("profile.update-password.reset-password")}
        </CustomButton>
      </Box>
    </Box>
  );
}
