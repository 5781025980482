import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LaunchIcon from "@material-ui/icons/Launch";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { toast } from "react-toastify";
import {
  changeOrsStatus,
  editStatusResource,
  enableOrDisableResource,
  fetchProcessExecutionOrsConf,
  restartResource
} from "../../../../../redux/actions/services";
import { resourceStatus } from "util";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import CustomTableCell from "../../../../../components/TableComponents/CustomTableCell";
import CustomTableRow from "../../../../../components/TableComponents/CustomTableRow";
import StatusBadge from "../../../../../components/StatusBadge";
import PermissionManagement from "../../../PermissionsManagement/PermissionsManagement";
import CustomEditIcon from "components/TableComponents/CustomActions/CustomEditIcon";
import { isPermitted } from "components/HasPermission";
import useStyles from "../../../../../components/TableComponents/style";
import { URL_GUACAMOLE } from "redux/constants/endpoints";
import CustomDialog from "components/CustomDialog";
import { Box } from "@material-ui/core";
import CustomSwitch from "pages/Services/components/CustomSwitch";
import CircularLoader from "components/Loaders/CircularLoader";
import { Fullscreen, FullscreenExit, Replay } from "@material-ui/icons";

export default function RobotTableRow({
  row,
  selected,
  setSelected,
  entityBaseUrl,
  fetchEntities,
  fetchEntitiesReset,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getFieldData = (valuePath) => get(row, valuePath);
  const active = getFieldData("isActive") === "ACTIVE";
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [resourceRestartConfirm, setResourceRestartConfirm] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [isPermissionPageOpen, setIsPermissionPageOpen] = useState(false);
  const [isOrsOpen, setIsOrsOpen] = useState(false);
  const [isOrsLoading, setIsOrsLoading] = useState(false);
  const [isOrsEnabled, setIsOrsEnabled] = useState(true);
  const [orsConnectionId, setOrsConnectionId] = useState(null);
  const [isLoading, setIsLoading] = useState({
    switch: false,
    deleteAction: false,
    restart: false
  });
  const [iframeDetails, setIframeDetails] = useState({
    width: null,
    height: null,
    src: null,
  });
  const [resourceIframeDetails, setResourceIframeDetails] = useState({
    width: null,
    height: null,
    src: null,
  });

  const deleteConfirmButton = getFieldData("isActive") === "ACTIVE"
      ? t("tooltip.action.hide")
      : t("tooltip.action.show");
  const displayFields = [
    {
      id: "displayStatus",
      width: 50,
      customContent: <StatusBadge level={getFieldData("displayStatus")} classes={classes} />,
    },
    {
      id: "resourceDisplayName",
      valuePath: "resourceDisplayName",
    },
    {
      id: "orchestratorName",
      valuePath: "orchestrator.name",
    },
    {
      id: "attributeName",
      valuePath: "attributeName",
    },
    {
      id: "displayStatus",
      valuePath: "displayStatus",
      defaultValue: "UNKNOWN",
      translate: true,
    },
    {
      id: "processesRunning",
      valuePath: "processesRunning",
    },
    {
      id: "last30DaysOccupancy",
      valuePath: "last30DaysOccupancy",
      format: (v) => v.toFixed(2),
      isPercentageValue: true
    },
    {
      id: "countAutomations",
      valuePath: "countAutomations",
    },
    {
      id: "countScheduledAutomations",
      valuePath: "countScheduledAutomations",
    },
  ];

  const resetLoaders = () => setIsLoading({ switch: false, deleteAction: false });

  const handleChangeStatus = () => {
    const id = getFieldData("id");
    setIsLoading({ ...isLoading, switch: true });
    dispatch(enableOrDisableResource(id, active ? "DISABLE" : "ACTIVE")).then(
      (err) => {
        resetLoaders();
        if (err?.response?.data?.detail) {
          toast.error(t(err?.response?.data?.detail));
        } else {
          fetchEntities();
        }
      },
    );
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
  };

  const cancelResourceRestart = () => {
    resetLoaders();
    setResourceRestartConfirm(false);
  };

  const confirmResourceRestart = () => {
    restartResourceConfirm();
    setResourceRestartConfirm(false);
  };

  const snackBarMessageOfResource = () => {
    const message = row.isActive === resourceStatus.ACTIVE ? t("hidden") : t("unhidden");
    toast.success(t("resource.hidden.success", { status: message }));
  };

  const confirmDelete = () => {
    onDeleteEntity();
  };

  const onDeleteEntity = () => {
    setIsLoading({ ...isLoading, deleteAction: true });
    dispatch(editStatusResource(toDeleteId)).then((res) => {
      if (res?.status === 200) {
        snackBarMessageOfResource();
        setTimeout(() => fetchEntitiesReset(), 1500);
      } else if (res?.response?.data?.detail) toast.error(t(res?.response?.data?.detail));
      else toast.error(t("Unexpected Error"));

      setOpenMsgConfirm(false);
      resetLoaders();
    });
  };

  const onCancel = () => {
    resetLoaders();
    setOpenConfirmationPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"),);
  const isCurrentUser = Number(currentUser?.id) === row?.id;

  const isResourceActive = row?.isActive === "ACTIVE";
  const deleteMsg = isResourceActive
    ? t("resource.management.hide.confirmMsg")
    : t("resource.management.show.confirmMsg");

  const generateText = (field) => {
    let text;
    if (field.translate) text = t(getFieldData(field?.valuePath) ?? field.defaultValue);
    if (field.format) text = field.format(getFieldData(field.valuePath));
    else text = getFieldData(field?.valuePath)
    if (field.isPercentageValue) text += "%";
    return text;
  }
  const onSuccessFetchOrsConf = (res) => {
    const {
  width, height, path, orsDisabled
 } = res.data;
    const src = `${process.env.REACT_APP_GATEWAY + URL_GUACAMOLE}/guacamole${path}`;
    setIframeDetails({ width, height, src });
    setResourceIframeDetails({ width, height, src });
    setIsOrsLoading(false)
    if (orsDisabled)
      { toast.warn("enable.ors"); }
   }
  const onErrorFetchOrsConf = (res) => {
    setIsOrsLoading(false)
    let message = "something went wrong";
    if (res.response.status === 422) message = "ors.connection.lost";
    toast.error(t(message));
    setIsOrsOpen(false)
  }
  const updateOrsStatus = () => {
    dispatch(changeOrsStatus(orsConnectionId, !isOrsEnabled, onSuccessChangeOrsStatus, onErrorChangeOrsStatus))
  }
  const onSuccessChangeOrsStatus = () => {
    setIsOrsEnabled((prevState) => !prevState);
    dispatch(fetchProcessExecutionOrsConf(orsConnectionId, onSuccessFetchOrsConf, onErrorFetchOrsConf))
  }
  const onErrorChangeOrsStatus = () => {
    toast.error(t("something went wrong"));
  }
  const onClickLaunch = (rowId, connectionId) => {
    setIsOrsOpen(true)
    setIsOrsLoading(true)
    setOrsConnectionId(connectionId)
    dispatch(fetchProcessExecutionOrsConf(connectionId, onSuccessFetchOrsConf, onErrorFetchOrsConf))
  };
  const areIframeDetailsEqual = () => resourceIframeDetails.height === iframeDetails.height && resourceIframeDetails.width === iframeDetails.width

  const setOrsDialogToMax = () => {
    if (areIframeDetailsEqual()) {
      const height = iframeDetails.height > 1080 ? iframeDetails.height + 90 : 1080;
      const width = iframeDetails.width > 1920 ? iframeDetails.width : 1920;
      setIframeDetails({ width, height, src: resourceIframeDetails.src });
    } else setIframeDetails(resourceIframeDetails);
  }

  const restartResourceOnSuccess = () => {
    toast.success(t("resource.restart.success"));
  }

  const restartResourceOnError = () => {
    toast.error(t("resource.restart.error"));
  }
  const restartResourceConfirm = () => {
    dispatch(restartResource(row?.id, restartResourceOnSuccess, restartResourceOnError));
  }

  return (
    <>
      <CustomTableRow
        onClick={() => history.push(`${entityBaseUrl}/view/${getFieldData("id")}`)}
      >
        {displayFields.map((field, i) => (
          <CustomTableCell
            key={i}
            title={t(field?.title)}
            text={generateText(field)}
            width={field?.width}
          >
            {field.customContent}
          </CustomTableCell>
        ))}
        <CustomTableCell>
          <Grid container xs={12} justify="flex-end" alignItems="center">
            {(row?.orsDetail && row?.orsDetail?.connectionId) && (
              <Grid item xs={3}>
                <IconButton onClick={(e) => {
                  e.stopPropagation()
                  onClickLaunch(row?.id, row?.orsDetail?.connectionId)
                }}>
                  <LaunchIcon />
                </IconButton>
              </Grid>
            )}
            { row?.resourceInfrastructure?.winSessionRestartable
            && (
            <Grid item>
              <Tooltip
                placement="top"
                title={t("tooltip.action.restart")}
              >
                <IconButton
                  disabled={isCurrentUser}
                  aria-label="delete"
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    e.stopPropagation();
                    setResourceRestartConfirm(true);
                  }}
                  className={classes.rowActionButtons}
                >
                  <Replay />
                </IconButton>
              </Tooltip>
            </Grid>
            )}
            {isPermitted(currentUser, "Edit Resources") && (
              <Grid item>
                <CustomEditIcon
                    id="admin-edit-btn"
                    aria-label="edit"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`${entityBaseUrl}/edit/${getFieldData("id")}`);
                    }}
                    className={classes.rowActionButtons}
                />
              </Grid>
            )}
            {isPermitted(currentUser, "Edit Resources") && (
              <Grid item>
                <Tooltip
                  placement="top"
                  title={
                    isResourceActive
                      ? t("tooltip.action.hide")
                      : t("tooltip.action.show")
                  }
                >
                  <IconButton
                    disabled={isCurrentUser}
                    aria-label="delete"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      setToDeleteId(getFieldData("id"));
                      setOpenMsgConfirm(true);
                    }}
                    className={classes.rowActionButtons}
                  >
                    {isResourceActive ? (
                      <VisibilityOffIcon
                          className={classes.hideIcon}
                      />
                    ) : (
                      <VisibilityIcon
                          className={classes.showIcon}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {isPermitted(currentUser, "Edit Resources") && (
              <Grid item>
                <Checkbox
                  className={classes.rowActionButtons}
                  checked={
                    !isCurrentUser && selected.includes(parseInt(row.id, 10))
                  }
                  disabled={isCurrentUser}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const list = [...selected];
                    e.target.checked
                      ? list.push(parseInt(getFieldData("id"), 10))
                      : list.splice(
                          list.indexOf(parseInt(getFieldData("id"), 10)),
                          1,
                        );
                    setSelected(list);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CustomTableCell>
      </CustomTableRow>
      <ConfirmMessage
        message={
          active
            ? t("resource.management.disable.confirmMsg")
            : t("resource.management.enable.confirmMsg")
        }
        openStart={openConfirmationPopup}
        onCancel={onCancel}
        onConfirm={handleChangeStatus}
        buttonConfirm={
          active
            ? t("resource.management.disable.button.confirm")
            : t("resource.management.enable.button.confirm")
        }
        buttonCancel={t("Cancel")}
        isLoading={isLoading.switch}
      />
      <ConfirmMessage
        message={t("action.resource.restart.desc")}
        openStart={resourceRestartConfirm}
        onCancel={cancelResourceRestart}
        onConfirm={confirmResourceRestart}
        buttonConfirm={t("action.resource.restart")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.restart}
      />
      <ConfirmMessage
        message={deleteMsg}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={deleteConfirmButton}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
      {isPermissionPageOpen && (
        <PermissionManagement
          t={t}
          dispatch={dispatch}
          setIsOpen={setIsPermissionPageOpen}
          isOpen={isPermissionPageOpen}
          classes={classes}
          row={row}
          fetchEntities={fetchEntities}
        />
      )}
      <CustomDialog
          open={isOrsOpen}
          onClose={() => setIsOrsOpen(false)}
          className={classes.orsDialog}
          width={iframeDetails?.width || 1400}
          height={iframeDetails?.height || 600}
          title={
            <Box display="flex" justifyContent="space-between" width="100%">
              <IconButton onClick={setOrsDialogToMax}>
                {areIframeDetailsEqual() ? <Fullscreen /> : <FullscreenExit />}
              </IconButton>
              {
                !isOrsLoading
                 && (<CustomSwitch
                      label={t("resource.management.formControl.activateOrs")}
                      handleChange={updateOrsStatus}
                      checked={isOrsEnabled}
                  />)
              }

            </Box>
          }
      >
        {isOrsLoading
        ? <CircularLoader />
        : <iframe
          src={iframeDetails?.src}
          width={iframeDetails?.width}
          height={iframeDetails?.height}
          title="Remote Session"
          className={classes.orsFrame}
        />}
      </CustomDialog>
    </>
  );
}
