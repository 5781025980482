export const FETCH_PROCESSES = "FETCH_PROCESSES";
export const FETCH_NOT_ASSIGNED_TAGS = "FETCH_NOT_ASSIGNED_TAGS";
export const FETCH_PROCESSES_SORTED_BY_USAGE = "FETCH_PROCESSES_SORTED_BY_USAGE";
export const FETCH_PROCESSES_FOR_FILTERS = "FETCH_PROCESSES_FOR_FILTERS";
export const FETCH_PROCESSES_FOR_ADMINISTRATION = "FETCH_PROCESSES_FOR_ADMINISTRATION";
export const FETCH_PROCESSES_NAMES = "FETCH_PROCESSES_NAMES";
export const FETCH_PROCESSES_KPI = "FETCH_PROCESSES_KPI";
export const FETCH_HEADER_KPI = "FETCH_HEADER_KPI";
export const FETCH_TODAY_UNHANDLED_EXCEPTIONS_KPI = "FETCH_TODAY_UNHANDLED_EXCEPTIONS_KPI";
export const FETCH_PROCESS_ASSIGNED_ROBOTS = "FETCH_PROCESS_ASSIGNED_ROBOTS"
export const FETCH_ROBOTS_BY_PROCESSES = "FETCH_ROBOTS_BY_PROCESSES"
export const FETCH_EXECUTIONS_ROBOTS = "FETCH_EXECUTIONS_ROBOTS"
export const FETCH_ALLOCATIONS = "FETCH_ALLOCATIONS";
export const FETCH_SHARED = "FETCH_SHARED";

export const FETCH_SCHEDULE = "FETCH_SCHEDULE";
export const FETCH_LINKS = "FETCH_LINKS";
export const UPDATE_PROCESS_LINKS = "UPDATE_PROCESS_LINKS";

export const FETCH_PROCESS_EXECUTION = "FETCH_PROCESS_EXECUTION";
export const FETCH_PROCESS_DETAILS = "FETCH_PROCESS_DETAILS";
export const UPDATE_PROCESS = "UPDATE_PROCESS";
export const UPDATE_PROCESS_DESCRIPTION = "UPDATE_PROCESS_DESCRIPTION";
export const UPDATE_PROCESS_PARTIALLY = "UPDATE_PROCESS_PARTIALLY";
export const SAVE_SCHEDULE = "SAVE_SCHEDULE";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const DELETE_SCHEDULE = "DELETE_SCHEDULE";
export const FETCH_WORK_QUEUE_ITEM = "FETCH_WORK_QUEUE_ITEM";
export const FETCH_WORK_QUEUE_ITEM_PROCESS_ITEM_EXCEPTION = "FETCH_WORK_QUEUE_ITEM_PROCESS_ITEM_EXCEPTION";
export const FETCH_WORK_QUEUE_ITEM_EXECPTIONS = "FETCH_WORK_QUEUE_ITEM_EXECPTIONS";
export const FETCH_WORK_QUEUE_ITEM_EXCEPTION_REASON = "FETCH_WORK_QUEUE_ITEM_EXCEPTION_REASON";
export const FETCH_WORK_QUEUE_ITEM_WORK_FLOW_STATUS = "FETCH_WORK_QUEUE_ITEM_WORK_FLOW_STATUS";
export const FETCH_WORK_QUEUE_ITEM_ASSINERS = "FETCH_WORK_QUEUE_ITEM_ASSINERS ";
export const FETCH_WORK_QUEUE_ITEM_EXCEPTION_TYPE = "FETCH_WORK_QUEUE_ITEM_EXCEPTION_TYPE";
export const DELETE_WORK_QUEUE_ITEM = "DELETE_WORK_QUEUE_ITEM";
export const FETCH_STATUS_PROCESS = "FETCH_STATUS_PROCESS";
export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const FETCH_DOCUMENT = "FETCH_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const FETCH_DOCUMENT_FILE = "FETCH_DOCUMENT_FILE";
export const FETCH_USERS_CIVILITIES = "FETCH_USERS_CIVILITIES";
export const FETCH_SUBSCRIPTIONS = "FETCH_SUBSCRIPTIONS";
export const FETCH_EXECUTION_TIME_ESTIMATION = "FETCH_EXECUTION_TIME_ESTIMATION";
export const FETCH_CURRENT_USER = "FETCH_CURRENT_USER";
export const FETCH_USERS_JOBS = "FETCH_USERS_JOBS";
export const FETCH_USERS_PERMISSIONS_TEMPLATES = "FETCH_USERS_PERMISSIONS_TEMPLATES";
export const ASSIGN_PROCESSES = "ASSIGN_PROCESSES";
export const FETCH_USER_PROCESSES = "FETCH_USER_PROCESSES";
export const FETCH_CONNECTED_USER_PROCESSES = "FETCH_CONNECTED_USER_PROCESSES";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_DEV_COST = "UPDATE_DEV_COST";
export const LATEST_FOUR_EXCEPTIONS = "LATEST_FOUR_EXCEPTIONS";
export const PLANNED_EXECUTIONS = "PLANNED_EXECUTIONS";
export const FETCH_ORCHESTRATORS = "FETCH_ORCHESTRATORS";
export const FETCH_ORCHESTRATOR = "FETCH_ORCHESTRATOR";
export const CREATE_ORCHESTRATOR = "CREATE_ORCHESTRATOR";
export const UPDATE_ORCHESTRATOR = "UPDATE_ORCHESTRATOR";
export const DELETE_ORCHESTRATOR_BY_ID = "DELETE_ORCHESTRATOR_BY_ID";
export const UPDATE_ORCHESTRATOR_STATUS = "UPDATE_ORCHESTRATOR_STATUS";
export const IS_ENOUGH_LICENSES = "IS_ENOUGH_LICENSES";
export const FETCH_ORCHESTRATOR_CONFIG_STATE = "FETCH_ORCHESTRATOR_CONFIG_STATE";
export const UPDATE_ORCHESTRATOR_CONFIG_STATE = "UPDATE_ORCHESTRATOR_CONFIG_STATE";
export const UPDATE_ROBOT_STATUS = "UPDATE_ROBOT_STATUS";
export const UPDATE_ORCHESTRATOR_IS_ACTIONABLE = "UPDATE_ORCHESTRATOR_IS_ACTIONABLE";
export const DELETE_LIST_ORCHESTRATORS = "DELETE_LIST_ORCHESTRATORS";
export const FETCH_PROCESS_LOGOS = "FETCH_PROCESS_LOGOS";
export const UPDATE_PROCESS_ICON = "UPDATE_PROCESS_ICON";
export const EXPORT_EXCEPTIONS = "EXPORT_EXCEPTIONS";
export const EXPORT_PROCESS_EXECUTION_EXCEPTIONS = "EXPORT_PROCESS_EXECUTION_EXCEPTIONS";
export const EXPORT_OCCURENCES = "EXPORT_OCCURENCES";
export const EXPORT_EXECUTIONS = "EXPORT_OCCURENCES";
export const EXPORT_FILTERED_EXECUTIONS = "EXPORT_FILTERED_EXECUTIONS";
export const EXPORT_ANALYSIS_TABLE_DATA = "EXPORT_ANALYSIS_TABLE_DATA";
export const UPDATE_BE_SINCE_DATE = "UPDATE_BE_SINCE_DATE";

export const DELETE_LIST_FLEETS = "DELETE_LIST_FLEETS";
export const DELETE_FLEET_BY_ID = "DELETE_FLEET_BY_ID";
export const UPDATE_FLEET_STATUS = "UPDATE_FLEET_STATUS";
export const FETCH_FLEETS = "FETCH_FLEETS";
export const FETCH_FLEET = "FETCH_FLEET";
export const CREATE_FLEET = "CREATE_FLEET";
export const UPDATE_FLEET = "UPDATE_FLEET";
export const FETCH_DIVISION = "FETCH_DIVISION";
export const FETCH_DIVISION_FOR_FILTERS = "FETCH_DIVISION_FOR_FILTERS";
export const UPDATE_DIVISION_STATUS = "UPDATE_DIVISION_STATUS";
export const DELETE_LIST_DIVISIONS = "DELETE_LIST_DIVISIONS";
export const DELETE_DIVISION_BY_ID = "DELETE_DIVISION_BY_ID";
export const FETCH_DIVISION_BY_ID = "FETCH_DIVISION_BY_ID";

export const FETCH_DIVISION_BY_FLEET_ID = "FETCH_DIVISION_BY_FLEET_ID";
export const UPDATE_DIVISION = "UPDATE_DIVISION";
export const ADD_DIVISION = "ADD_DIVISION";
export const FETCH_PROCESSES_LIST_BY_DIVISION_AND_USER = "FETCH_PROCESSES_LIST_BY_DIVISION_AND_USER";
export const FETCH_DIVISIONS_BY_USER = "FETCH_DIVISIONS_BY_USER";

export const FETCH_PROCESSES_LIST_BY_DIVISIONS = "FETCH_PROCESSES_LIST_BY_DIVISIONS";

export const FETCH_PROCESSES_LIST_BY_FLEET = "FETCH_PROCESSES_LIST_BY_FLEET";
export const CREATE_USER_AWS = "CREATE_USER_AWS";
export const CHECK_AWS_USER_EXISTS = "CHECK_AWS_USER_EXISTS";

export const FETCH_USERS = "FETCH_USERS";
export const FETCH_FLEET_USERS = "FETCH_FLEET_USERS";
export const FETCH_USER = "FETCH_USER";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER_BY_ID = "DELETE_USER_BY_ID";
export const DELETE_LIST_USERS = "DELETE_LIST_USERS";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const FETCH_ALL_FLEETS_FOR_FILTER = "FETCH_ALL_FLEETS_FOR_FILTER";
export const FETCH_FLEET_PROCESSES = "FETCH_FLEET_PROCESSES";
export const VALIDATE_PROCESS_ASSIGN_FROM_FLEET = "VALIDATE_PROCESS_ASSIGN_FROM_FLEET";
export const FETCH_PAYMENT_TERMS = "FETCH_PAYMENT_TERMS";
export const FETCH_ANALYSIS_DATA = "FETCH_ANALYSIS_DATA";
export const FETCH_PROCESSES_BY_ORCHESTRATOR = "FETCH_PROCESSES_BY_ORCHESTRATOR";
export const FETCH_NOT_ASSIGNED_PROCESSES_BY_ORCHESTRATOR = "FETCH_NOT_ASSIGNED_PROCESSES_BY_ORCHESTRATOR";
export const FETCH_ANALYSIS_TABLE_DATA = "FETCH_ANALYSIS_TABLE_DATA";
export const FETCH_USER_PERMISSIONS = "FETCH_USER_PERMISSIONS";

// Default alert actions
export const DELETE_DEFAULT_ALERT_BY_ID = "DELETE_DEFAULT_ALERT_BY_ID";
export const DELETE_CUSTOM_ALERT_BY_ID = "DELETE_CUSTOM_ALERT_BY_ID";
export const FETCH_DEFAULT_ALERTS = "FETCH_DEFAULT_ALERTS";
export const FETCH_CUSTOM_ALERTS = "FETCH_DECUSTOM_ALERTS";
export const FETCH_DEFAULT_ALERT = "FETCH_DEFAULT_ALERT";
export const FETCH_CUSTOM_ALERT = "FETCH_CUSTOM_ALERT";
export const CREATE_DEFAULT_ALERT = "CREATE_DEFAULT_ALERT";
export const CREATE_CUSTOM_ALERT = "CREATE_CUSTOM_ALERT";
export const UPDATE_DEFAULT_ALERT = "UPDATE_DEFAULT_ALERT";
export const UPDATE_CUSTOM_ALERT = "UPDATE_CUSTOM_ALERT";
export const UPDATE_DEFAULT_ALERT_EMAIL_STATUS = "UPDATE_DEFAULT_ALERT_EMAIL_STATUS";
export const UPDATE_CUSTOM_ALERT_EMAIL_STATUS = "UPDATE_CUSTOM_ALERT_EMAIL_STATUS";
export const UPDATE_DEFAULT_ALERT_SMS_STATUS = "UPDATE_DEFAULT_ALERT_SMS_STATUS";
export const UPDATE_DEFAULT_ALERT_SUBSCRIPTION_STATUS = "UPDATE_DEFAULT_ALERT_SUBSCRIPTION_STATUS";
export const UPDATE_CUSTOM_ALERT_SMS_STATUS = "UPDATE_CUSTOM_ALERT_SMS_STATUS";
export const UPDATE_CUSTOM_ALERT_SUBSCRIPTION_STATUS = "UPDATE_CUSTOM_ALERT_SUBSCRIPTION_STATUS";
export const RESET_CUSTOM_ALERT_SCHEDULER = "RESET_CUSTOM_ALERT_SCHEDULER";
export const FETCH_AVAILABLE_RPA_QUAL_STATUS = "FETCH_AVAILABLE_RPA_QUAL_STATUS";
export const FETCH_PROCESS_EXECUTION_EXCEPTIONS = "FETCH_PROCESS_EXECUTION_EXCEPTIONS";
export const FETCH_PROCESS_EXECUTION_EXCEPTIONS_COUNT = "FETCH_PROCESS_EXECUTION_EXCEPTIONS_COUNT";
export const FETCH_PROCESS_EXECUTION_EXCEPTION_REASON = "FETCH_PROCESS_EXECUTION_EXCEPTION_REASON";
export const FETCH_PROCESS_EXECUTION_ORS_CONF = "FETCH_PROCESS_EXECUTION_ORS_CONF";
export const CHANGE_PROCESS_EXECUTION_ORS_STATUS = "CHANGE_PROCESS_EXECUTION_ORS_STATUS";
export const REVOKE_PRIORITY_FROM_EXECUTION = "REVOKE_PRIORITY_FROM_EXECUTION";
export const REQUEST_PROCESS_EXECUTION_STOP = "REQUEST_PROCESS_EXECUTION_STOP";
export const REQUEST_PROCESS_STOP = "REQUEST_PROCESS_STOP";
export const FETCH_PROCESS_EXECUTION_EXCEPTION_TYPE = "FETCH_PROCESS_EXECUTION_EXCEPTION_TYPE";
export const DELETE_PROCESS_EXECUTION = "DELETE_PROCESS_EXECUTION";
export const FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_PROCESS = "FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_PROCESS";
export const FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_REASON = "FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_REASON";
export const FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_TYPE = "FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_TYPE";
export const FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_TREND = "FETCH_PROCESS_EXCEPTION_ANALYTICS_KPI_BY_TREND";
export const FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_PROCESS = "FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_PROCESS";
export const FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_REASON = "FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_REASON";
export const FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_TYPE = "FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_TYPE";
export const FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_TREND = "FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_TREND";
export const FETCH_ROBOTS = "FETCH_ROBOTS";
export const FETCH_ROBOT = "FETCH_ROBOT";
export const UPDATE_ROBOT = "UPDATE_ROBOT";
export const FETCH_ROBOT_PROCESSES = "FETCH_ROBOT_PROCESSES";
export const SAVE_PROCESS_RESTRICTION = "SAVE_PROCESS_RESTRICTION";
export const FETCH_PROCESS_RESTRICTION = "FETCH_PROCESS_RESTRICTION";
export const EXIST_PROCESS_RESTRICTION_PRIORITY = "EXIST_PROCESS_RESTRICTION_PRIORITY";
export const FETCH_UNAVAILABLE_PRIORITIES = "FETCH_UNAVAILABLE_PRIORITIES";
export const FETCH_PROCESSES_BY_FLEET = "FETCH_PROCESSES_BY_FLEET";
export const FETCH_ROBOT_NAMES = "FETCH_ROBOT_NAMES";
export const FETCH_PROCESS_PLANNER = "FETCH_PROCESS_PLANNER";
export const FETCH_ROBOTS_BY_ORCHESTRATOR = "FETCH_ROBOTS_BY_ORCHESTRATOR";
export const FETCH_ORCHESTRATORS_NAMES_AND_IDS = "FETCH_ORCHESTRATORS_NAMES_AND_IDS";
export const FETCH_PLANNER_PROCESSES_LIST = "FETCH_PLANNER_PROCESSES_LIST";
export const FETCH_PLANNER_FILTER_ROBOTS = "FETCH_PLANNER_FILTER_ROBOTS";
export const TEST_RESOURCE_CONNECTION = "TEST_RESOURCE_CONNECTION";

export const FETCH_ALL_EXECUTIONS = "FETCH_ALL_EXECUTIONS";
export const FETCH_ALL_EXECUTIONS_COUNT = "FETCH_ALL_EXECUTIONS_COUNT";
export const FETCH_QUEUE_PROCESSES = "FETCH_QUEUE_PROCESSES";
// BP Connector
export const TEST_ORCHESTRATOR_CONNECTION = "TEST_ORCHESTRATOR_CONNECTION";
export const INIT_ORCHESTRATOR_DATABASE = "INIT_ORCHESTRATOR_DATABASE";
export const TRIGGER_UPDATE_IN_ORCHESTRATOR = "TRIGGER_UPDATE_IN_ORCHESTRATOR";

// UIPATH Connector
export const TEST_ORCHESTRATOR_UIPATH_CONNECTION = "TEST_ORCHESTRATOR_UIPATH_CONNECTION";

// Synchro
export const FETCH_ORCHESTRATOR_STATUS = "FETCH_ORCHESTRATOR_STATUS";
export const INIT_ORCHESTRATOR_SYNCHRO = "INIT_ORCHESTRATOR_SYNCHRO";

// Risk Management
export const UPDATE_PROCESS_RISK_TIME = "UPDATE_PROCESS_RISK_TIME";
// Process Data Mapping
export const FETCH_PROCESS_DATA_MAPPING = "FETCH_PROCESS_DATA_MAPPING";
export const UPDATE_PROCESS_DATA_MAPPING = "UPDATE_PROCESS_DATA_MAPPING";
// Process Inputs
export const FETCH_PROCESS_INPUTS = "FETCH_PROCESS_INPUTS";

// Retry exceptions
export const RETRY_ITEM_EXCEPTION = "RETRY_ITEM_EXCEPTION";
export const CHECK_PENDING_RETRY = "CHECK_PENDING_RETRY";
export const BULK_RETRY_ITEM_EXCEPTION = "BULK_RETRY_ITEM_EXCEPTION";

export const FETCH_WORK_QUEUE_ITEM_BY_PROCESS = "FETCH_WORK_QUEUE_ITEM_BY_PROCESS";

// Exception workflow
export const TAKE_EXCEPTION = "TAKE_EXCEPTION";
export const BULK_TAKE_EXCEPTION = "BULK_TAKE_EXCEPTION";
export const ASSIGN_EXCEPTION = "ASSIGN_EXCEPTION";
export const BULK_ASSIGN_EXCEPTION = "BULK_ASSIGN_EXCEPTION";
export const EXCEPTION_ASSIGN_NOTIFY = "EXCEPTION_ASSIGN_NOTIFY";
export const RELEASE_EXCEPTION = "RELEASE_EXCEPTION";
export const BULK_RELEASE_EXCEPTION = "RELEASE_EXCEPTION";
export const MARK_EXCEPTION_AS_DONE = "MARK_EXCEPTION_AS_DONE";
export const BULK_MARK_EXCEPTION_AS_DONE = "MARK_EXCEPTION_AS_DONE";
export const REOPEN_EXCEPTION = "REOPEN_EXCEPTION";
export const BULK_REOPEN_EXCEPTION = "BULK_REOPEN_EXCEPTION";
export const FETCH_ANALYTICS_PRESETS = "FETCH_ANALYTICS_PRESETS";
export const FETCH_CUSTOM_KPIs = "FETCH_CUSTOM_KPIs";
export const CREATE_UPDATE_CUSTOM_KPIs = "CREATE_UPDATE_CUSTOM_KPIs";
export const DELETE_CUSTOM_KPIs = "DELETE_CUSTOM_KPIs";
export const DELETE_ANALYTICS_PRESET = "DELETE_ANALYTICS_PRESET";
export const CREATE_ANALYTICS_PRESET = "CREATE_ANALYTICS_PRESET";
export const FETCH_EXPORTED_FILES = "FETCH_EXPORTED_FILES";
export const EXPORT_FILE_BY_NAME = "EXPORT_FILE_BY_NAME";
export const EXPORT_ALL_FILES = "EXPORT_ALL_FILES";
export const DELETE_EXPORTED_FILE = "DELETE_EXPORTED_FILE";
export const DELETE_ALL_EXPORTED_FILES = "DELETE_ALL_EXPORTED_FILES";
export const FETCH_EXCEPTION_WORKFLOW_HISTORY = "FETCH_EXCEPTION_WORKFLOW_HISTORY";
// Automation Exception workflow
export const TAKE_AUTOMATION_EXCEPTION = "TAKE_AUTOMATION_EXCEPTION";
export const BULK_TAKE_AUTOMATION_EXCEPTION = "BULK_TAKE_AUTOMATION_EXCEPTION";
export const ASSIGN_AUTOMATION_EXCEPTION = "ASSIGN_AUTOMATION_EXCEPTION";
export const BULK_ASSIGN_AUTOMATION_EXCEPTION = "BULK_ASSIGN_AUTOMATION_EXCEPTION";
export const AUTOMATION_EXCEPTION_ASSIGN_NOTIFY = "AUTOMATION_EXCEPTION_ASSIGN_NOTIFY";
export const RELEASE_AUTOMATION_EXCEPTION = "RELEASE_AUTOMATION_EXCEPTION";
export const BULK_RELEASE_AUTOMATION_EXCEPTION = "BULK_RELEASE_AUTOMATION_EXCEPTION";
export const MARK_AUTOMATION_EXCEPTION_AS_DONE = "MARK_AUTOMATION_EXCEPTION_AS_DONE";
export const BULK_MARK_AUTOMATION_EXCEPTION_AS_DONE = "BULK_MARK_AUTOMATION_EXCEPTION_AS_DONE";
export const REOPEN_AUTOMATION_EXCEPTION = "REOPEN_AUTOMATION_EXCEPTION";
export const BULK_REOPEN_AUTOMATION_EXCEPTION = "BULK_REOPEN_AUTOMATION_EXCEPTION";
export const FETCH_AUTOMATION_EXCEPTION_WORKFLOW_HISTORY = "FETCH_AUTOMATION_EXCEPTION_WORKFLOW_HISTORY";
// SR Queues
export const FETCH_SR_QUEUES = "FETCH_SR_QUEUES";
export const FETCH_SR_QUEUES_NAMES_AND_IDS = "FETCH_SR_QUEUES_NAMES_AND_IDS";
export const FETCH_SR_QUEUE = "FETCH_SR_QUEUE";
export const CREATE_OR_UPDATE_SR_QUEUE = "CREATE_SR_QUEUE";
export const DELETE_SR_QUEUE = "DELETE_SR_QUEUE";
export const DELETE_SR_QUEUES = "DELETE_SR_QUEUES";
export const RESET_SR_QUEUE = "RESET_SR_QUEUE";
export const UPDATE_SR_QUEUE_STATUS = "UPDATE_SR_QUEUE_STATUS";
export const GET_REMAINING_LICENCES_FOR_ORCHESTRATOR = "GET_REMAINING_LICENCES_FOR_ORCHESTRATOR";
export const FETCH_VIEWS_COUNT = "FETCH_VIEWS_COUNT";
export const IS_PROCESS_ASSIGNED_TO_QUEUE = "IS_PROCESS_ASSIGNED_TO_QUEUE";
export const FETCH_SR_QUEUE_EXECUTIONS = "FETCH_SR_QUEUE_EXECUTIONS";
export const REMOVE_EXECUTION_FROM_QUEUE = "REMOVE_EXECUTION_FROM_QUEUE";
export const UPDATE_SR_EXECUTION_PRIORITY = "UPDATE_SR_EXECUTION_PRIORITY";
export const REFRESH_QUEUES_FROM_DB = "REFRESH_QUEUES_FROM_DB";
export const FETCH_QUEUE_ROBOTS = "FETCH_QUEUE_ROBOTS";
export const HANDLE_PRE_DELETE_QUEUE = "HANDLE_PRE_DELETE_QUEUE";
export const CHECK_IF_QUEUS_HAVE_SCHEDULES_WITHOUT_ROBOTS = "CHECK_IF_QUEUS_HAVE_SCHEDULES_WITHOUT_ROBOTS";
export const CHECK_IF_QUEUS_HAVE_MANUAL_EXECUTION_WITH_SAME_ROBOT = "CHECK_IF_QUEUS_HAVE_MANUAL_EXECUTION_WITH_SAME_ROBOT";
export const HAS_QUEUE_PENDING_EXECUTIONS = "HAS_QUEUE_PENDING_EXECUTIONS";
export const MIGRATE_QUEUES_SCHEDULES = "MIGRATE_QUEUES_SCHEDULES";
export const DELETE_QUEUES_SCHEDULES = "DELETE_QUEUES_SCHEDULES";

// Qualification des processus
export * from "./opportunities.constants"
export const CREATE_PROCESS_COST = "CREEATE_PROCESS_COST";
export const CREATE_SHARED_COST = "CREATE_SHARED_COST";
export const CREATE_SHARED_ALL = "CREATR_SHARED_ALL";
export const UPDATE_COST_ALLOCATION = "UPDATE_COST_ALLOCATION";
export const UPDATE_SHARED = "UPDATE_SHARED";
export const UPDATE_LIST_SHARED = "UPDATE_LIST_SHARED";
export const DELETE_COST_ITEM = "DELETE_COST_ITEM";
export const DELETE_COST_SHARED = "DELETE_COST_SHARED";
export const DELETE_SHARED_ALL = "DELETE_SHARED_ALL";
export const DELETE_COST_ALLOCATION = "DELETE_COST_ALLOCATION";

// Tags
export const ADD_TAG = "ADD_TAG";
export const FETCH_FLEET_TAGS = "FETCH_FLEET_TAGS";
export const DELETE_TAG_BY_ID = "DELETE_TAG_BY_ID";
export const DELETE_LIST_TAGS = "DELETE_LIST_TAGS";
export const FETCH_TAG = "FETCH_TAG";
export const FETCH_TAG_BY_ID = "FETCH_TAG_BY_ID";
export const FETCH_TAG_FOR_FILTERS = "FETCH_TAG_FOR_FILTERS";
export const FETCH_TAG_FOR_PLANNER_FILTER = "FETCH_TAG_FOR_PLANNER_FILTER";
export const UPDATE_TAG = "UPDATE_TAG";
export const ARCHIVE_TAG = "ARCHIVE_TAG";
export const BULK_ARCHIVE_TAG = "BULK_ARCHIVE_TAG";
export const UPDATE_TAG_STATUS = "UPDATE_TAG_STATUS";
export const FETCH_ICON = "FETCH_ICON";
export const FETCH_TAGS_FOR_FILTERS = "FETCH_TAGS_FOR_FILTERS";
export const FETCH_LICENSE = "FETCH_LICENSE";
export const SAVE_LICENSE = "SAVE_LICENSE";
export const FETCH_WORK_QUEUE_ITEM_EXECPTIONS_COUNT = "FETCH_WORK_QUEUE_ITEM_EXECPTIONS_COUNT";
export const FETCH_WORK_QUEUE_ITEM_COUNT = "FETCH_WORK_QUEUE_ITEM_COUNT";
export const FETCH_ALL_TAGS_OF_USER = "FETCH_ALL_TAGS_OF_USER";
export const FETCH_DASHBOARD_ROI = "FETCH_DASHBOARD_ROI";
export const FETCH_DASHBOARD_BREAK_EVEN = "FETCH_DASHBOARD_BREAK_EVEN";
export const FETCH_DASHBOARD_UNHANDLED_EXCEPTIONS = "FETCH_DASHBOARD_UNHANDLED_EXCEPTIONS";
export const FETCH_DASHBOARD_TIME_SAVED = "FETCH_DASHBOARD_TIME_SAVED";
export const FETCH_DASHBOARD_ROBOT_PRODUCTIVITY = "FETCH_DASHBOARD_ROBOT_PRODUCTIVITY";
export const EXPORT_DASHBOARD_ROBOT_PRODUCTIVITY = "EXPORT_DASHBOARD_ROBOT_PRODUCTIVITY";
export const FETCH_DASHBOARD_PROCESSES_ACTIVITY = "FETCH_DASHBOARD_PROCESSES_ACTIVITY";
export const FETCH_DASHBOARD_HANDLING_TIME = "FETCH_DASHBOARD_HANDLING_TIME";
export const FETCH_EXCEPTION_ASSIGMENT_RULES = "FETCH_EXCEPTION_ASSIGMENT_RULES";
export const ADD_EXCEPTION_ASSIGMENT_RULE = "ADD_EXCEPTION_ASSIGMENT_RULE";
export const UPDATE_EXCEPTION_ASSIGMENT_RULE = "UPDATE_EXCEPTION_ASSIGMENT_RULE";
export const DELETE_EXCEPTION_ASSIGMENT_RULE = "DELETE_EXCEPTION_ASSIGMENT_RULE";
export const FETCH_EXCEPTION_ASSIGMENT_RULE_BY_ID = "FETCH_EXCEPTION_ASSIGMENT_RULE_BY_ID";
export const FETCH_FILTER_PROCESSES = "FETCH_FILTER_PROCESSES";
export const FETCH_FILTER_ROBOTS = "FETCH_FILTER_ROBOTS";
export const FETCH_DASHBOARD_TRANSACTIONS = "FETCH_DASHBOARD_TRANSACTIONS";
export const FETCH_DASHBOARD_FTE_SAVED = "FETCH_DASHBOARD_FTE_SAVED";
export const FETCH_DASHBOARD_SUCCESS_RATE = "FETCH_DASHBOARD_SUCCESS_RATE";
export const FETCH_DASHBOARD_WEEKLY_FTE_SAVED = "FETCH_DASHBOARD_WEEKLY_FTE_SAVED";
export const FETCH_DASHBOARD_WEEKLY_SUCCESS_RATE = "FETCH_DASHBOARD_WEEKLY_SUCCESS_RATE";
export const FETCH_DASHBOARD_WEEKLY_TRANSACTIONS = "FETCH_DASHBOARD_WEEKLY_TRANSACTIONS";
export const FETCH_DASHBOARD_AVERAGE_HANDLING_TIME = "FETCH_DASHBOARD_AVERAGE_HANDLING_TIME";
export const FETCH_DASHBOARD_TODAY_STATS = "FETCH_DASHBOARD_TODAY_STATS";
export const FETCH_DASHBOARD_ALL_TIME_STATS = "FETCH_DASHBOARD_ALL_TIME_STATS";
export const FETCH_DASHBOARD_PENDING_ITEMS_COUNT = "FETCH_DASHBOARD_PENDING_ITEMS_COUNT";
export const FETCH_DASHBOARD_PROCESSES_STATUS_COUNT = "FETCH_DASHBOARD_PROCESSES_STATUS_COUNT";
export const FETCH_AUDIT_LOGS = "FETCH_AUDIT_LOGS";
export const FETCH_EXPORT_AUDIT_LOGS = "FETCH_EXPORT_AUDIT_LOGS";
export const FETCH_AUDIT_ACTIONS = "FETCH_AUDIT_ACTIONS";
export const FETCH_AUDIT_USERS = "FETCH_AUDIT_USERS";
export const FETCH_AUDIT_MODULES = "FETCH_AUDIT_MODULES";
export const FETCH_PERMISSIONS_ROLES = "FETCH_PERMISSIONS_ROLES";
export const ADD_PERMISSIONS_ROLE = "ADD_PERMISSIONS_ROLE";
export const UPDATE_PERMISSIONS_ROLE = "UPDATE_PERMISSIONS_ROLE";
export const DELETE_PERMISSIONS_ROLE = "DELETE_PERMISSIONS_ROLE";
export const FETCH_PERMISSIONS_ROLE_BY_ID = "FETCH_PERMISSIONS_ROLE_BY_ID";
export const FETCH_FLEETS_FOR_PERMISSIONS_ROLES = "FETCH_FLEETS_FOR_PERMISSIONS_ROLES";
export const FETCH_PENDING_AUTOMATIONS = "FETCH_PENDING_AUTOMATIONS";
export const FETCH_PENDING_AUTOMATIONS_TAGS = "FETCH_PENDING_AUTOMATIONS_TAGS";
export const FETCH_PENDING_AUTOMATIONS_USERS = "FETCH_PENDING_AUTOMATIONS_USERS";
export const FETCH_AUTOMATION_USERS = "FETCH_AUTOMATION_USERS";
export const GENERATE_DATA = "GENERATE_DATA";
export const FETCH_AUTOMATION_GROUPS = "FETCH_AUTOMATION_GROUPS";
export const FETCH_ALL_ORCHESTRATIONS = "FETCH_ALL_ORCHESTRATIONS";
export const FETCH_ORCHESTRATION_BY_ID = "FETCH_ORCHESTRATION_BY_ID";
export const SAVE_ORCHESTRATION = "SAVE_ORCHESTRATION";
export const DELETE_ORCHESTRATION = "DELETE_ORCHESTRATION";
export const UPDATE_ORCHESTRATION = "UPDATE_ORCHESTRATION";
export const DISABLE_ORCHESTRATIONS = "DISABLE_ORCHESTRATIONS";
export const UPDATE_ORCHESTRATION_STATUS = "UPDATE_ORCHESTRATION_STATUS";
export const FETCH_WORK_QUEUES_BY_ORCHESTRATOR_ID = "FETCH_WORK_QUEUES_BY_ORCHESTRATOR_ID";
export const FETCH_ALL_EXECUTION_REQUESTS = "FETCH_ALL_EXECUTION_REQUESTS";
export const FETCH_ALL_HISTORICAL_EXECUTION_REQUESTS = "FETCH_ALL_HISTORICAL_EXECUTION_REQUESTS";
export const FETCH_RESOURCE_CREDENTIALS_BY_USER_ID = "FETCH_RESOURCE_CREDENTIALS_BY_USER_ID";
export const UPDATE_RESOURCE_CREDENTIAL = "UPDATE_RESOURCE_CREDENTIAL";
export const FETCH_RESOURCE_CREDENTIALS = "FETCH_RESOURCE_CREDENTIALS";
export const CREATE_RESOURCE_CREDENTIAL = "CREATE_RESOURCE_CREDENTIAL";
export const FETCH_RESOURCE_CREDENTIAL_BY_ID = "FETCH_RESOURCE_CREDENTIAL_BY_ID";
export const DELETE_RESOURCE_CREDENTIAL = "DELETE_RESOURCE_CREDENTIAL";
