import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import clsx from "clsx";
import { REGEX, formatTimezone } from "util";
import { getTimezoneOffset } from "date-fns-tz";
import {
  fetchCivilities,
  fetchCurrentUser,
  updateProfile,
} from "redux/actions/services";
import { useDispatch } from "react-redux";
import { FETCH_CURRENT_USER, FETCH_USERS_CIVILITIES } from "../../../redux/constants";
import { useQuery } from "@redux-requests/react";
import CustomAutoComplete, {
  CustomTextField,
} from "components/FormFields/CustomAutoComplete";
import CustomButton from "components/CustomButton";
import CustomSelectField from "components/FormFields/CustomSelectField";
import supportedLanguages from "components/Language/supportedLanguages";
import ConfirmMessage from "components/ConfirmMessage";
import useStyles from "../style";

function UserInformation({ logo }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const civilities = useQuery({ type: FETCH_USERS_CIVILITIES })?.data;
  const dispatch = useDispatch();

  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timezones, setTimezones] = React.useState([]);
  const idUser = currentUser?.id;
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    register,
    control,
  } = useForm({
    defaultValues: {
      lastName: "",
      firstName: "",
      civility: "",
      phoneNumber: "",
    },
  });
  const handleSaveClick = async () => {
    setOpenMsgConfirm(true);
  };

  const onSaveSuccess = () => {
    dispatch(fetchCurrentUser());
    toast.success(t("user.update.success"));
    setIsLoading(false);
    setOpenMsgConfirm(false);
  }

  const onSaveError = () => {
    toast.error(t("user.update.fail"));
    setIsLoading(false);
    setOpenMsgConfirm(false);
  }

  const saveUserProfile = (data) => {
    setIsLoading(true);
    const dataToSubmit = {
      ...data,
      civility: civilities.find((civ) => civ.code === data.civility),
    };

    dispatch(updateProfile(dataToSubmit, logo, onSaveSuccess, onSaveError));
  };

  const confirmSave = () => {
    handleSubmit(saveUserProfile)();
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };

  useEffect(() => {
    dispatch(fetchCivilities())

    if (currentUser) {
      setValue("civility", currentUser?.civility?.code);
      setValue("lastName", currentUser?.lastName);
      setValue("firstName", currentUser?.firstName);
      setValue("phoneNumber", currentUser?.phoneNumber);
      setValue("local", currentUser?.local);
      setValue("timeZone", currentUser?.timeZone);
      setValue(
        "communicationLang",
        supportedLanguages
          ?.map(({ key }) => key)
          ?.includes(currentUser?.communicationLang)
          ? currentUser?.communicationLang
          : "en"
      );
    }
  }, [currentUser]);

  useEffect(() => {
    const timezones_ = Intl.supportedValuesOf("timeZone")
      .map((time) => ({
        name: time,
        offset: getTimezoneOffset(time, new Date()) / 3.6e6,
      }))
      .map((e) => ({
        ...e,
        label: formatTimezone(e.name, e.offset, e.name),
      }))
      .sort((a, b) => a.offset - b.offset || a.label.localeCompare(b.label));
    setTimezones(timezones_);
    return () => setTimezones([]);
  }, []);

  return (
    <>
      <Box
      className={clsx(
        classes.profileSectionContainer,
        classes.profileUserContainer
      )}
      component="form"
    >
        <Box className={classes.profileSection}>
          <Typography className={classes.profileSectionTitle}>
            {t("user.information")}
          </Typography>
          <Box display="grid" gridTemplateColumns="1FR 1FR" gridColumnGap="30px">
            <CustomTextField
              value={currentUser?.fleet?.companyName}
              variant="standard"
              fullWidth
              size="small"
              label={t("user.administration.fleet")}
              className={classes.textField}
              InputProps={{
                readOnly: true,
              }}
          />
            <CustomSelectField
              {...register("civility", {
                required: {
                  value: true,
                  message: t("user.management.formControl.required"),
                },
              })}
              value={getValues("civility")}
              options={civilities || []}
              optionLabel="label"
              optionValue="code"
              valued={getValues("")}
              onChange={(e) => {
                clearErrors("civility");
                setValue("civility", e.target.value);
              }}
              variant="standard"
              label={`${t("user.add.civility")} *`}
              formControlClassName={classes.selectField}
              error={!!errors.civility}
              helperText={!!errors.civility && errors?.civility?.message}
              isCustom
          />
            <CustomTextField
              {...register("lastName", {
                required: {
                  value: true,
                  message: t("user.management.formControl.required"),
                },
              })}
              valued={getValues("lastName")}
              variant="standard"
              fullWidth
              size="small"
              label={t(`${t("user.add.lastName")} *`)}
              className={classes.textField}
              InputLabelProps={{
                shrink: !!getValues("lastName"),
              }}
              error={!!errors.lastName}
              helperText={!!errors.lastName && errors?.lastName?.message}
          />
            <CustomTextField
              {...register("firstName", {
                required: {
                  value: true,
                  message: t("user.management.formControl.required"),
                },
              })}
              valued={getValues("firstName")}
              variant="standard"
              fullWidth
              size="small"
              label={`${t("user.add.firstName")} *`}
              className={classes.textField}
              InputLabelProps={{
                shrink: !!getValues("firstName"),
              }}
              error={!!errors.firstName}
              helperText={!!errors.firstName && errors?.firstName?.message}
          />
            <CustomTextField
              value={currentUser?.email}
              variant="standard"
              fullWidth
              size="small"
              label={`${t("user.add.email")} *`}
              className={classes.textField}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: currentUser?.fleet?.email?.trim(),
              }}
          />
            <CustomTextField
              {...register("phoneNumber", {
                pattern: {
                  value: REGEX.TEL,
                  message: t("user.management.formControl.phone"),
                },
              })}
              valued={getValues("phoneNumber")}
              variant="standard"
              fullWidth
              size="small"
              label={t("fleet.management.formLabel.phoneNumber")}
              className={classes.textField}
              InputLabelProps={{
                shrink: !!getValues("phoneNumber"),
              }}
          />
          </Box>

        </Box>
        <Box className={classes.profileSection} mt="30px">
          <Typography className={classes.profileSectionTitle}>
            {t("Preferences")}
          </Typography>
          <CustomSelectField
          {...register("communicationLang", {
            required: {
              value: true,
              message: t("user.management.formControl.required"),
            },
          })}
          value={getValues("communicationLang")}
          options={supportedLanguages || []}
          optionLabel="text"
          optionValue="key"
          onChange={(e) => {
            clearErrors("communicationLang");
            setValue("communicationLang", e.target.value);
          }}
          variant="standard"
          label={`${t("user.add.comm.lang")} *`}
          formControlClassName={classes.textField}
          error={!!errors.communicationLang}
          helperText={
            !!errors.communicationLang && errors?.communicationLang?.message
          }
          isCustom
        />
          <Box display="grid" gridTemplateColumns="1FR 1FR" gridColumnGap="30px">
            <Controller
            {...register("timeZone")}
            key={getValues("timeZone")}
            control={control}
            rules={{
              required: {
                value: true,
                message: t("user.management.formControl.required"),
              },
            }}
            render={({ field }) => (
              <FormControl className={classes.textField}>
                <CustomAutoComplete
                  options={timezones || []}
                  value={timezones?.find(
                    ({ name }) => name === getValues("timeZone")
                  )}
                  onChange={(newValue) => {
                    field?.onChange(newValue?.name);
                    setValue("timeZone", newValue?.name);
                  }}
                  optionLabel="label"
                  optionUuid="name"
                  label={t("time.zone")}
                  error={!!errors?.timeZone}
                  helperText={errors?.timeZone && errors?.timeZone.message}
                  inputRef={field?.ref}
                  fullWidth
                  disableCloseOnSelect
                />
              </FormControl>
            )}
          />

            <CustomSelectField
            {...register("local")}
            value={getValues("local")}
            options={supportedLanguages || []}
            optionLabel="text"
            optionValue="key"
            onChange={(e) => {
              clearErrors("local");
              setValue("local", e.target.value);
            }}
            variant="standard"
            formControlClassName={classes.textField}
            label={t("language")}
            isCustom
          />
          </Box>
        </Box>
        <ConfirmMessage
        message={
          idUser ? t("user.update.confirmMsg") : t("user.save.confirmMsg")
        }
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmSave}
        buttonConfirm={idUser ? t("user.button.update") : t("user.button.save")}
        buttonCancel={t("Cancel")}
        isLoading={isLoading}
      />
      </Box>
      <Box />
      <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
            marginTop="30px"
            className={classes.profileUserContainer}
        >
        <CustomButton
              view="primary"
              disabled={false}
              size="small"
              type="submit"
              onClick={handleSubmit(handleSaveClick)}
          >
          {idUser ? t("user.button.update") : t("user.button.save")}
        </CustomButton>
      </Box>
    </>
  );
}

export default UserInformation;
