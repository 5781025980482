import React, { useEffect, useState } from "react";
import CustomDialog from "components/CustomDialog";
import { Box, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import DefaultAlertInfo from "./DefaultAlertInfo/index";
import {
  createDefaultAlert,
  fetchDefaultAlert,
  fetchUserProcesses,
  updateDefaultAlert,
  fetchFleetUsers, fetchFleetsForFilter,
} from "../../../../redux/actions/services";

import EditAlertButton from "../../components/EditButton";
import CloseAlertButton from "../../components/CloseButton";
import { toast } from "react-toastify";
import { isSRQueuesDisabled } from "util/constants/PermitedFeature";
import { DISCONNECTED_ROBOT_WHEN } from "util/constants/alerts";
import { isFleetAdministrator } from "util";
import CircularLoader from "components/Loaders/CircularLoader";
import useStyles from "./style";

const menuItems = {
  whenMenuItem: [
    "New opportunity created",
    "Opportunity archived",
    "Schedule added",
    DISCONNECTED_ROBOT_WHEN,
    "A license is one month away from expiry",
    "A license is two months away from expiry",
    ...!isSRQueuesDisabled ? ["execution is deleted", "execution is missed", "deleted by sr policy"] : [],
  ],
};

function DefaultAlertDialog({
  open, handleClose, mode, idAlert, handleDefaultAlertEdit
}) {
  const classes = useStyles();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [defaultAlert, setDefaultAlert] = React.useState({});

  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);

  const [openMsgCancel, setOpenMsgCancel] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const [processMenuItem, setProcessMenuItem] = useState([]);

  const [sameFleetUsers, setSameFleetUsers] = useState();

  const [subscribersIds, SetSubscribersIds] = useState();
  const [userFleet, setUserFleet] = useState([]);
  const [isInstanceOwner, setIsInstanceOwner] = useState(false);
  const [selectedFleet, setSelectedFleet] = useState(null);
  const [
    selectedSubscribers,
    setSelectedSubscribers,
  ] = React.useState([]);
  const [defaultFleetSelected, setDefaultFleetSelected] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    watch,
    trigger,
    reset,
  } = useForm();

  const headerMapping = {
    add: "alerts.addHeader",
    edit: "alerts.editHeader",
    view: "alerts.displayHeader",
    default: "alerts.displayHeader"
  };

  const disableFields = !(mode === "add" || mode === "edit");

  // eslint-disable-next-line no-nested-ternary

  const onSubmit = (data) => {
    setOpenMsgConfirm(true);
    let dataToSend = data;
    let alertSubscribersIds = selectedSubscribers.map((e) => e.id);
    // eslint-disable-next-line no-nested-ternary
    alertSubscribersIds = alertSubscribersIds?.length > 1
        ? alertSubscribersIds?.join(",")
        : alertSubscribersIds?.length === 1
        ? alertSubscribersIds?.join("")
        : "";
    dataToSend = {
      ...data,
      alertSubscribersIds,
    };

    dataToSend = {
      ...dataToSend,
      fleetId: selectedFleet,
      type: "DEFAULT"
    }
    if (dataToSend.when === DISCONNECTED_ROBOT_WHEN)
    {
      dataToSend.resourceDtos = dataToSend.resourcesIds.map((id) => ({ id }))
      dataToSend.whenVariables = { disconnectionPeriod: dataToSend.disconnectionPeriod }
    }
    setDefaultAlert(dataToSend);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  useEffect(() => {
    if (currentUser?.fleet?.id) { dispatch(fetchUserProcesses(currentUser?.id)).then((res) => setProcessMenuItem(res.data?.list?.filter((item) => !item.isDisabled))); }
    setIsInstanceOwner(currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser));
  }, [currentUser]);
  useEffect(() => {
    SetSubscribersIds([currentUser?.id]);
    setValue("sub", [currentUser?.id]);
  }, [open]);
  useEffect(() => {
    if (isInstanceOwner) {
      dispatch(fetchFleetsForFilter()).then((res) => {
        setUserFleet(res.data);
      });
    }
  }, [isInstanceOwner])
  const confirmSave = () => {
    setIsLoading(true);
    if (mode === "add") {
      dispatch(
        createDefaultAlert(defaultAlert, (res) => {
          if (res?.status === 200) {
            handleClose(true);
            setOpenMsgConfirm(false);
            setIsLoading(false);
          }
        }),
      )
        .then((res) => {
          if (res.response.status !== "OK") {
            resetFields();
            setOpenMsgConfirm(false);
            setIsLoading(false);
            toast.error(res.response?.data?.message)
          } else {
            toast.success(t("alert.management.formControl.saveSuccess"))
          }
        })
        .catch(() => {});
    } else {
      dispatch(
        updateDefaultAlert(
          idAlert,
          defaultAlert,

          (res) => {
            if (res?.status === 200) {
              resetFields();
              setOpenMsgConfirm(false);
              handleClose(true);
              setIsLoading(false);
              toast.success(t("alert.management.formControl.updateSuccess"))
            }
          },
        ),
      ).then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            setIsLoading(false);
            toast.error(res.response?.data?.message)
          }
        })
        .catch(() => {});
    }
  };

  const handleCancel = () => setOpenMsgCancel(true);

  const cancelConfirm = () => setOpenMsgConfirm(false);

  const handleRejectCancelForm = () => setOpenMsgCancel(false);

  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    handleClose(false);
    resetFields();
  };

  const handleSubscribersChange = (event) => {
    const value = event?.target?.value || event;
    setSelectedSubscribers(value);
    setValue("sub", value);
  }
  const handleChangeFleet = (e) => {
    if (e) {
      setSelectedFleet(e?.id);
    }
  }
  const getUsersFleet = (fleetId) => {
    dispatch(fetchFleetUsers(fleetId)).then((result) => {
      setSameFleetUsers(result.data);
      const selectedUsers = result?.data?.filter((e) => subscribersIds?.includes(e?.id.toString()))
      setSelectedSubscribers(selectedUsers);
    });
  }
  useEffect(() => {
    getUsersFleet(currentUser?.fleet?.id);
  }, [subscribersIds])
  useEffect(() => {
    if (selectedFleet) {
      getUsersFleet(selectedFleet);
    }
  }, [selectedFleet]);
  const getDefaultAlert = () => {
    if (mode !== "add") {
      setIsDataLoading(true);
      dispatch(fetchDefaultAlert(idAlert)).then((res) => {
        const userIds = !(res?.data?.alertSubscribersIds?.split(",")) ? [] : (res?.data?.alertSubscribersIds?.split(","));
        SetSubscribersIds(userIds);
        setDefaultFleetSelected(res?.data?.tinyFleetDto);
        setSelectedFleet(res?.data?.fleetId);
        setValue("alertName", res?.data?.alertName);
        setValue("alertDescription", res?.data?.alertDescription);
        setValue("when", res?.data?.when);
        setValue("flag", res?.data?.flag);
        setValue("status", res?.data?.status);
        setValue("sub", userIds);
        setValue("robotsIds", res?.data?.robotDtos?.map(({ id }) => id))
        setValue("disconnectionPeriod", res?.data?.whenVariables?.disconnectionPeriod)
        if (res?.data?.processId) setValue("processId", res?.data?.processId);
        if (res?.data?.orchestratorId) setValue("orchestratorId", res?.data?.orchestratorId);
        if (res?.data?.queueId) setValue("queueId", res?.data?.queueId);
        if (res?.data?.resourceDtos) setValue("resourcesIds", res?.data?.resourceDtos?.map(({ id }) => id));
        setIsDataLoading(false);
      });
    }
  }

  useEffect(() => {
    getDefaultAlert();
  }, [idAlert]);

  const resetFields = () => {
    reset();
    setSelectedSubscribers([]);
    setSelectedFleet(null);
    setDefaultFleetSelected(null);
    SetSubscribersIds([currentUser?.id]);
    setSameFleetUsers([]);
  }
  return (
    <CustomDialog
          open={open}
          onClose={handleClose}
          maxWidth="xl"
          classes={{
            paper: classes.dialogPaper,
          }}
          actionElements={!disableFields && [
            {
                label: t("Cancel"),
                onClick: () => handleCancel(false),
                variant: "contained",
                size: "medium",
                className: classes.resetButton,
            },
            {
                label: idAlert
                  ? t("alerts.management.button.update")
                  : t("alerts.management.button.save"),
                view: "primary",
                size: "medium",
                type: "submit",
                onClick: () => handleSubmit(onSubmit)(),
            }
        ]}
      >
      <Grid container justify="center">
        <Grid item xs={8} justify="flex-start">
          <Typography variant="h5" className={classes.title}>
            {t(headerMapping[mode] || headerMapping.default)}
          </Typography>
        </Grid>
        <Grid item xs={4} container justify="flex-end" spacing={4}>
          <Grid item>
            {mode === "view" && (
              <EditAlertButton
                mode={mode}
                currentUser={currentUser}
                onClick={handleDefaultAlertEdit}
              />
            )}
          </Grid>
          <Grid item>
            {mode === "view" && (
              <CloseAlertButton
                onClick={() => {
                  handleClose(false);
                  resetFields();
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      { isDataLoading ? (
        <CircularLoader style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
      ) : (
        <Box className={classes.alertInfoContainer}>
          <form className={classes.fleetForm} onSubmit={handleSubmit(onSubmit)}>
            <Grid container item>
              <DefaultAlertInfo
                classes={classes}
                control={control}
                register={register}
                trigger={trigger}
                watch={watch}
                errors={errors}
                disableFields={disableFields}
                menuItems={menuItems}
                clearErrors={clearErrors}
                mode={mode}
                processMenuItem={processMenuItem}
                sameFleetUsers={sameFleetUsers}
                selectedSubscribers={selectedSubscribers}
                setSameFleetUsers={setSameFleetUsers}
                handleSubscribersChange={handleSubscribersChange}
                userFleet={userFleet}
                handleChangeFleet={handleChangeFleet}
                selectedFleet={selectedFleet}
                isInstanceOwner={isInstanceOwner}
                defaultFleetSelected={defaultFleetSelected}
              />
            </Grid>
          </form>
          {openMsgConfirm && (
            <ConfirmMessage
              message={
                idAlert
                  ? t("alerts.management.update.confirmMsg")
                  : t("alerts.management.save.confirmMsg")
              }
              openStart={openMsgConfirm}
              onCancel={cancelConfirm}
              onConfirm={confirmSave}
              buttonConfirm={
                idAlert
                  ? t("alerts.management.button.update")
                  : t("alerts.management.button.save")
              }
              buttonCancel={t("alerts.management.button.cancel")}
              isLoading={isLoading}
            />
          )}
          {openMsgCancel && (
            <ConfirmMessage
              message={t("alerts.management.delete.discard")}
              openStart={openMsgCancel}
              onCancel={handleRejectCancelForm}
              onConfirm={handleAcceptCancelForm}
              buttonConfirm={t("alerts.management.button.discard")}
              buttonCancel={t("alerts.management.button.cancel")}
            />
          )}
        </Box>
      )}
    </CustomDialog>
  );
}
export default DefaultAlertDialog;
