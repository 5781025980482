import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Grid from "@material-ui/core/Grid";
import debounce from "lodash/debounce";
import CustomAutoComplete from "components/FormFields/CustomAutoComplete";
import NoDataMenu from "components/NoData/NoDataMenu";
import ClearFilter from "components/ClearFilter";
import DatePeriodField from "components/DatePeriodField";
import { SearchFilter } from "components/Filter";
import AuditService from "redux/actions/services/audit";
import { initialFilter, updateFilter } from "redux/slices/auditFilterSlice";
import { formatDatePickerByLanguage } from "util";
import ExportButton from "components/ExportButton/index.js";
import PageHeader from "components/PageHeader";
import ReportingMenu from "pages/Reporting/components/Filter/ReportingMenu";

export default function AuditFilter({
  handleExportCsv,
  exportLoading,
  setAnchorEl,
  anchorEl
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auditFilters = useSelector(({ auditFilters }) => auditFilters);
  const [searchQuery, setSearchQuery] = useState(auditFilters.searchQuery);
  const [filtersData, setFiltersData] = useState({});

  const AUDIT_LOGS = useSelector(({ requests }) => get(requests, "queries.FETCH_AUDIT_LOGS.data"));

  const searchLogs = (keyWord) => {
    dispatch(
      updateFilter({
        ...auditFilters,
        pageNo: 0,
        searchQuery: keyWord,
      })
    );
  };
  const debouncer = useCallback(
    debounce((nextValue) => searchLogs(nextValue), 500),
    [auditFilters]
  );
  const handleSearchChange = ({ target: { value } }) => {
    debouncer(value);
    setSearchQuery(value);
  };
  const handleChangeFilter = (filterSlice) => {
    dispatch(
      updateFilter({
        ...auditFilters,
        ...filterSlice,
        pageNo: 0,
      })
    );
  };
  const handleClear = () => {
    dispatch(initialFilter());
  };

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const dispatchFilterRequest = (fetchService, filterProp) => {
    dispatch(fetchService(
      auditFilters,
      (res) => {
        if (res.status === 200) {
          setFiltersData((prevState) => ({ ...prevState, [filterProp]: res.data ? res.data : [] }));
        } else {
          toast.error(t("Unexpected Error"));
        }
      },
      (err) => {
        toast.error(t(err.response?.data?.title || err.message));
      }
    ));
  }

  useEffect(() => {
    setSearchQuery(auditFilters.searchQuery);
  }, [auditFilters.searchQuery]);

  useEffect(() => {
    dispatchFilterRequest(AuditService.fetchAuditActions, "action");
    dispatchFilterRequest(AuditService.fetchAuditUsers, "username");
    dispatchFilterRequest(AuditService.fetchAuditModules, "module");
  }, []);

  useEffect(() => {
    dispatchFilterRequest(AuditService.fetchAuditActions, "action");
  }, [auditFilters.modules, auditFilters.users, auditFilters.period, auditFilters.searchQuery]);

  useEffect(() => {
    dispatchFilterRequest(AuditService.fetchAuditUsers, "username");
  }, [auditFilters.modules, auditFilters.actions, auditFilters.period, auditFilters.searchQuery]);

useEffect(() => {
    dispatchFilterRequest(AuditService.fetchAuditModules, "module");
  }, [auditFilters.users, auditFilters.actions, auditFilters.period, auditFilters.searchQuery]);

  return (
    <Grid container item spacing={2} alignItems="flex-end">
      <Grid container item justify="space-between" alignItems="center">
        <PageHeader title="Audit" />
        <Grid container item xs={1} justify="flex-end">
          <ExportButton
            loading={exportLoading}
            onClick={handleClick}
            disabled={!AUDIT_LOGS}
          />
        </Grid>
      </Grid>
      <Grid item container xs={2}>
        <SearchFilter
          callback={handleSearchChange}
          value={searchQuery}
        />
      </Grid>
      <Grid item xs={2}>
        <CustomAutoComplete
              multiple
              options={filtersData?.module || []}
              value={filtersData?.module?.filter((u) => auditFilters.modules.includes(u))}
              noOptionsNode={<NoDataMenu message={t("no.module.message")} />}
              onChange={(v) => handleChangeFilter({ modules: v })}
              label={t("audit.module")}
          />
      </Grid>
      <Grid item xs={2}>
        <CustomAutoComplete
          multiple
          options={filtersData?.username || []}
          value={filtersData?.username?.filter((u) => auditFilters.users.includes(u))}
          noOptionsNode={<NoDataMenu message={t("no.user.message")} />}
          onChange={(v) => handleChangeFilter({ users: v })}
          label={t("audit.user")}
        />
      </Grid>
      <Grid item xs={2}>
        <CustomAutoComplete
          multiple
          options={filtersData?.action || []}
          value={filtersData?.action?.filter((a) => auditFilters.actions.includes(a))}
          noOptionsNode={<NoDataMenu message={t("no.action.message")} />}
          onChange={(v) => handleChangeFilter({ actions: v })}
          label={t("audit.action")}
        />
      </Grid>
      <DatePeriodField
        handlePeriodChange={(p) => {
          handleChangeFilter({
            period: {
              from: p.start,
              to: p.end,
              duration: p.duration,
            },
          });
        }}
        period={{
          start: auditFilters.period.from,
          end: auditFilters.period.to,
          duration: auditFilters.period.duration,
        }}
        dateFormat={`${formatDatePickerByLanguage(false)} HH:mm`}
        isCustom
        xs={2}
      />
      <Grid item xs={1}>
        <ClearFilter clearFilter={handleClear} />
      </Grid>
      <ReportingMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        options={[{ label: "reporting.exportCSV", onClick: handleExportCsv }]}
      />
    </Grid>
  );
}
