import React, { useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid"
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import {
  fetchAssignedResourcesByProcess,
  fetchProcessesByOrchestrator,
  fetchOrchestrationById,
  saveOrchestration,
  updateOrchestration,
  fetchOrchestratorsNamesAndIds,
  fetchWorkQueuesByOrchestratorId,
} from "redux/actions/services/index";
import {
  INTELLIGENT_ORCHESTRATORS_BASE_URL, formatDatePickerByLanguage, getDateAndTime, getLocale,
  parseTimeString
} from "util/index";
import ConfirmMessage from "components/ConfirmMessage";
import CustomButton from "components/CustomButton";
import CircularLoader from "components/Loaders/CircularLoader";
import DialogWithTabs from "components/FormComponents/DialogWithTabs";
import CustomAutoComplete, { CustomTextField } from "components/FormFields/CustomAutoComplete";
import {
  Box, FormControl, Switch, Typography
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TodayOutlinedIcon from "@material-ui/icons/TodayOutlined";
import clsx from "clsx";
import SlaInfo from "./SlaInfo";
import SlaForm from "./SlaForm";
import makeStyles from "./style";
import { get } from "lodash";
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CustomSelectField from "components/FormFields/CustomSelectField";
import WeekDaysCalendar from "./WeekDaysCalendar";
import DayHoursSelector from "./DayHoursSelector";
import {
  days, hours, maxSessionsActions, minutes, months, processPriorities, quartzInitialDays, stopModes, triggers, weeks, years
} from "../util";
import { fetchResourceCredentialsByUserId } from "redux/actions/services/resourceCredential";

const defaultSchedulingInfo = {
  startDate: new Date(),
  allowedStartTime: "00:00",
  allowedEndTime: "23:59",
  frequency: 1,
  unitType: "HOURLY",
  allowedDays: [],
  executionTime: "00:00",
  isDayFixed: true,
  weekOrder: 1,
  scheduleTypeDayOfMonth: null
}

export default function OrchestrationForm(props) {
  const classes = makeStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    setValue, formState: { dirtyFields, errors }, getValues, register, watch
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      orchestratorId: "",
      processId: "",
      trigger: null,
      workQueueId: "",
      stopRequest: null,
      processPriority: "",
      resourceGroupName: "",
      maxConccurentSession: 1,
      timeout: 0,
      resouceGroupIds: [],
      isActive: true,
      orchestrationSlas: [],
      schedulingInfo: defaultSchedulingInfo,
      maxSessionsAction: null,
      resourceCredentialId: null
    },
  });
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const history = useHistory();
  const { idOrchestration, mode = "add" } = useParams();
  const [openMsgCancel, setOpenMsgCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [orchestrationDataLoading, setOrchestrationDataLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const disableFields = !(mode === "add" || mode === "edit");
  const [slas, setSlas] = useState(watch("orchestrationSlas") || []);
  const [isSlaFormOpen, setIsSlaFormOpen] = useState(false);
  const [processes, setProcesses] = useState([]);
  const [queues, setQueues] = useState([]);
  const [orchestrators, setOrchestrators] = useState([]);
  const [processesResources, setProcessesResources] = useState([]);
  const [isViewMode, setIsViewMode] = useState(false);
  const [selectedSlaIndex, setSelectedSlaIndex] = useState(null);
  const [weekDays, setWeekDays] = useState(quartzInitialDays);
  const [resourceCredentials, setResourceCredentials] = useState([]);
  const [useResourceSpecificCredentials, setUseResourceSpecificCredentials] = useState(true);
  const periods = [
    {
      value: "MINUTELY", label: t("Minutely"), frequency: minutes, frequencyLabel: t("Minute(s)")
     },
    {
      value: "HOURLY", label: t("Hourly"), frequency: hours, frequencyLabel: t("Hour(s)")
    },
    {
      value: "DAILY", label: t("Daily"), frequency: days, frequencyLabel: t("Day(s)")
    },
    {
      value: "WEEKLY", label: t("Weekly"), frequency: weeks, frequencyLabel: t("Week(s)")
    },
    {
      value: "MONTHLY", label: t("Monthly"), frequency: months, frequencyLabel: t("Month(s)")
    },
    {
      value: "YEARLY", label: t("Yearly"), frequency: years, frequencyLabel: t("Year(s)")
    },
  ]
  const dayOfStartDate = getValues("schedulingInfo.startDate") ? new Date(getValues("schedulingInfo.startDate"))?.getDate() : 1;
  const dayOrder = [
    { value: 1, label: t("First") },
    { value: 2, label: t("Second") },
    { value: 3, label: t("Third") },
    { value: 4, label: t("Fourth") },
    { value: 5, label: t("Last") },
  ];
  const scheduleTypeDayOfMonth = [
    "NTH_DAY_OF_MONTH",
    "SPECIFIC_DAYS_OF_WEEK_IN_MONTH",
    ...(dayOfStartDate >= 29 ? ["LAST_DAY_OF_MONTH"] : [])
  ];
  const tabs = [
    {
      id: "orchestration-overview-tab",
      label: "Orchestration",
    },
    {
      id: "sla-tab",
      label: "SLA",
      disabled: watch("trigger") !== triggers[0],
    },
    {
      id: "scheduling-tab",
      label: "scheduling",
      disabled: watch("trigger") !== triggers[1],
    },
    {
      id: "infrastracture-tab",
      label: "Orchestration Group",
    },
  ];
  const enabledTabs = tabs.filter((item) => !item?.disabled);
  const targetPeriod = useMemo(() => periods.find(({ value }) => value === watch("schedulingInfo.unitType")), [watch("schedulingInfo.unitType")]);

  useEffect(() => {
    if (idOrchestration) {
      setOrchestrationDataLoading(true);
      dispatch(fetchOrchestrationById(idOrchestration)).then((res) => {
        if (res?.data) {
          setValue("orchestrationId", res.data.id);
          setValue("name", res.data.name);
          setValue("description", res.data.description);
          setValue("processId", res.data.processId);
          setValue("orchestratorId", res.data.orchestratorId);
          setValue("trigger", res.data.trigger);
          setValue("workQueueId", res.data.workQueueId);
          setValue("stopRequest", res.data.stopRequest);
          setValue("processPriority", res.data.processPriority);
          setValue("resourceGroupName", res.data.resourceGroupName);
          setValue("resouceGroupIds", res.data.resouceGroupIds);
          setValue("maxConccurentSession", res.data.maxConccurentSession);
          setValue("timeout", res.data.timeout);
          setValue("isActive", res.data.isActive);
          setValue("orchestrationSlas", res.data.orchestrationSlas);
          setValue("maxSessionsAction", res.data?.maxSessionsAction);
          setValue("schedulingInfo", res.data?.schedulingInfo);
          setValue("resourceCredentialId", res.data?.resourceCredentialId);
          if (res.data?.schedulingInfo?.allowedDays) {
            const data = res.data?.schedulingInfo?.allowedDays
            const newDays = quartzInitialDays.map((day) => ({ ...day, checked: data.includes(day.id) }));
            setWeekDays(newDays);
          } else {
            const newDays = quartzInitialDays.map((day) => ({ ...day, checked: false }));
            setWeekDays(newDays);
          }
          setSlas(res.data?.orchestrationSlas);
          setOrchestrationDataLoading(false);
          setUseResourceSpecificCredentials(res.data?.resourceCredentialId === null)
        }
      });
    }
  }, [idOrchestration, dispatch, setValue]);

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchOrchestratorsNamesAndIds()).then((res) => {
        if (res?.data) {
          setOrchestrators(res.data);
        }
      });
      dispatch(fetchResourceCredentialsByUserId(currentUser?.id)).then((res) => {
        if (res?.data) {
          setResourceCredentials(res.data);
        }
      });
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (watch("orchestratorId")) {
      dispatch(fetchProcessesByOrchestrator(watch("orchestratorId"))).then(
        (res) => {
          if (res?.data) {
            setProcesses(res.data);
          }
        },
      );
    }
  }, [dispatch, watch("orchestratorId")]);

  useEffect(() => {
    if (watch("orchestratorId")) {
      dispatch(fetchWorkQueuesByOrchestratorId(watch("orchestratorId"))).then(
        (res) => {
          if (res?.data) {
            setQueues(res.data);
          }
        },
      );
    }
  }, [dispatch, watch("orchestratorId")]);

  useEffect(() => {
    if (watch("processId")) {
      dispatch(fetchAssignedResourcesByProcess(watch("processId"))).then(
        (res) => {
          setProcessesResources(res?.data);
        },
      );
    }
  }, [dispatch, watch("processId")]);

  useEffect(() => {
    if (watch("trigger") === triggers[1] && !watch("schedulingInfo")) {
      setValue("schedulingInfo", defaultSchedulingInfo);
    }
  }, [watch("trigger")]);

  const handleSaveClick = async () => {
    if (activeStep === enabledTabs.length - 1) {
      setOpenMsgConfirm(true);
    }
    if (activeStep < enabledTabs.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleCancel = () => {
    if (isEmpty(dirtyFields)) {
      redirectToList();
      return;
    }
    setOpenMsgCancel(true);
  };

  const redirectToList = () => history.push(INTELLIGENT_ORCHESTRATORS_BASE_URL);

  const handleBack = () => {
    if (activeStep <= 0) handleCancel();
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 0 : prevActiveStep - 1));
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };

  const confirmSave = () => {
    setIsLoading(true);
    submitUpdate();
  };

  const onSuccess = () => {
    setIsLoading(false);
    setOpenMsgConfirm(false);
    toast.success(idOrchestration ? t("orchestration.formControl.succesUpdate") : t("orchestration.formControl.succesSave"));
    redirectToList();
  };

  const onError = () => {
    setIsLoading(false);
    setOpenMsgConfirm(false);
    toast.error(idOrchestration ? t("orchestration.formControl.errorUpdate") : t("orchestration.formControl.errorSave"));
  };

  const saveOrUpdateOrchestration = async () => {
    if (getValues("trigger") === triggers[0]) {
      setValue("schedulingInfo", null);
    } else {
      if (!["YEARLY"].includes(getValues("schedulingInfo.unitType")) && !getValues("isDayFixed")) {
        setValue("schedulingInfo.allowedDays", weekDays.filter((day) => day.checked).map((day) => day.id));
      } else {
        setValue("schedulingInfo.weekOrder", null);
        setValue("schedulingInfo.isDayFixed", true);
        setValue("schedulingInfo.allowedDays", []);
      }
      setValue("workQueueId", null);
      setValue("resourceCredentialId", useResourceSpecificCredentials ? null : getValues("resourceCredentialId"));
    }
    if (idOrchestration) {
      dispatch(updateOrchestration(idOrchestration, getValues(), onSuccess, onError))
    }
    else {
      dispatch(saveOrchestration(getValues(), onSuccess, onError))
    }
  };

  const submitUpdate = () => {
    setValue("orchestrationSlas", slas);
    saveOrUpdateOrchestration();
    setIsLoading(false);
    setOpenMsgConfirm(false);
  };

  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    redirectToList();
  };

  const handleRejectCancelForm = () => setOpenMsgCancel(false);

  const handleChange = (_, newValue) => {
    setActiveStep(newValue);
  };

  const defaultSla = {
    name: "",
    description: "",
    tagFilter: "",
    responseTimeCalc: null,
    deadline: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    averageRunningTime: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  };

  const handleAddSla = () => {
    setValue("orchestrationSlas", [...slas, defaultSla]);
    setSelectedSlaIndex(slas.length);
    setIsSlaFormOpen(true);
    setIsViewMode(false);
  };
  const composeScheduleDescription = () => {
    let firstDescription = t("orchestration.schedule.desc", {
      frequency: getValues("schedulingInfo.frequency") > 1 ? getValues("schedulingInfo.frequency") : "",
      period: t(periods.find((period) => period.value === getValues("schedulingInfo.unitType"))?.frequencyLabel),
      executionTime: !["MINUTELY", "HOURLY"].includes(getValues("schedulingInfo.unitType")) ? getValues("schedulingInfo.executionTime") : "",
    });
    if (!["MINUTELY", "HOURLY"].includes(getValues("schedulingInfo.unitType"))) firstDescription += `${t("orchestration.schedule.desc.hour", { executionTime: getValues("schedulingInfo.executionTime") })}`;
    let days = "";
    let secondDescription = "";
    const numberOfSelectedDays = weekDays.filter((day) => day.checked).length
    if ((numberOfSelectedDays > 0 && numberOfSelectedDays < 7) || getValues("schedulingInfo.unitType") === "MONTHLY") {
      const filteredDays = weekDays.filter((day) => day.checked === numberOfSelectedDays <= 4)
      filteredDays.forEach((day, index) => {
        if (index === 0) {
          days += `${t(day.fullLabel)}`;
        }
        else if (index !== filteredDays.length - 1) {
          days += `, ${t(day.fullLabel)}`;
        }
        else if (index === filteredDays.length - 1) {
          days += ` ${t("and")} ${t(day.fullLabel)}`;
        }
      })
      if (getValues("schedulingInfo.unitType") === "MONTHLY") {
        if (getValues("schedulingInfo.scheduleTypeDayOfMonth") === scheduleTypeDayOfMonth[1] && getValues("schedulingInfo.weekOrder") && selectedDayId) {
          secondDescription = `. ${t("the")} ${dayOrder[+getValues("schedulingInfo.weekOrder") - 1].label.toLocaleLowerCase()} ${t(quartzInitialDays[+selectedDayId - 1].fullLabel).toLocaleLowerCase()} ${t("of.the.month")}`
        } else if (getValues("schedulingInfo.scheduleTypeDayOfMonth") !== scheduleTypeDayOfMonth[1]) {
          secondDescription = `. ${composeDayConditionLabel(getValues("schedulingInfo.scheduleTypeDayOfMonth")).toLocaleLowerCase()}`
        }
      }
      else secondDescription = numberOfSelectedDays > 4 ? t("orchestration.schedule.desc.week.except", { days }) : t("orchestration.schedule.desc.week", { days });
    }
    return `${firstDescription} ${secondDescription}`
  }

  const handleStartDateChange = (date) => {
    setValue("schedulingInfo.startDate", date);
    handleDaysOfWeekChangeFromStartDay(date);
  }
  const handleUnitTypeChange = (event) => {
    setValue("schedulingInfo.unitType", event.target.value);
    handleDaysOfWeekChangeFromStartDay(getValues("schedulingInfo.startDate"));
  }
  const handleDaysOfWeekChangeFromStartDay = (date) => {
    const dayOfWeek = date.getDay() || 7;
    if (["WEEKLY"].includes(getValues("schedulingInfo.unitType"))) {
      setWeekDays(weekDays.map((day) => ({ ...day, checked: day.id === dayOfWeek })));
    }
    else if (["MONTHLY"].includes(getValues("schedulingInfo.unitType")) && getValues("schedulingInfo.scheduleTypeDayOfMonth") === scheduleTypeDayOfMonth[1]) {
      setWeekDays(weekDays.map((day) => ({ ...day, checked: day.id === dayOfWeek })));
    }
    else if (["MINUTELY", "HOURLY", "DAILY"].includes(getValues("schedulingInfo.unitType"))) {
      setWeekDays(weekDays.map((day) => {
        if (day.id === dayOfWeek) {
          return { ...day, checked: true };
        }
        return day;
      }));
    }
    else setWeekDays(weekDays.map((day) => ({ ...day, checked: false })));
  }

  const isMonthly = () => getValues("schedulingInfo.unitType") === "MONTHLY";

  const onDaySelect = (dayValue) => {
    setWeekDays(weekDays.map((d) => ({ ...d, checked: d.value === dayValue?.value })));
  }

  const selectedDayId = weekDays.find((d) => d.checked)?.id;

  const onDayActionChange = (value) => {
    setValue("schedulingInfo.scheduleTypeDayOfMonth", value)
    if (value === scheduleTypeDayOfMonth[1]) {
      handleDaysOfWeekChangeFromStartDay(getValues("schedulingInfo.startDate"));
    } else {
      setWeekDays(weekDays.map((d) => ({ ...d, checked: false })));
    }
  }

  const composeDayConditionLabel = (condition) => {
    if (condition !== scheduleTypeDayOfMonth[0]) {
      return t(condition);
    }
    if (dayOfStartDate >= 4) return t(condition, { day: dayOfStartDate });
    return t(`${condition}.${dayOfStartDate}`);
  }

  const tabsContents = [
    {
      content: (
          orchestrationDataLoading ? (
            <CircularLoader height="100%" />
          ) : (
            <Grid container direction="column" alignItems="center" spacing={4}>
              <Grid container item justify="space-between">
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("name", {
                          required: {
                            value: true,
                            message: t("queue.management.form.validation.required"),
                          },
                        })}
                        error={!!errors?.name?.message}
                        helperText={errors?.name?.message}
                        fullWidth
                        InputProps={{
                          readOnly: disableFields,
                        }}
                        id="Orchestration"
                        label={t("Orchestration")}
                        InputLabelProps={{
                          shrink: !!watch("name"),
                        }}
                        autoFocus
                        onChange={(e) => setValue("name", e.target.value)}
                    />
                </Grid>
                <Grid item xs={5}>
                  <CustomTextField
                        {...register("description", {
                          required: {
                            value: true,
                            message: t("queue.management.form.validation.required"),
                          },
                        })}
                        error={!!errors?.description?.message}
                        helperText={errors?.description?.message}
                        onChange={(e) => setValue("description", e.target.value)}
                        fullWidth
                        id="description"
                        name="description"
                        label={t("Description")}
                        InputProps={{
                          readOnly: disableFields,
                        }}
                        InputLabelProps={{
                          shrink: watch("description"),
                        }}
                    />
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item xs={5}>
                  <CustomAutoComplete
                        fullWidth
                        InputProps={{
                          readOnly: disableFields,
                        }}
                        id="orchestrationId"
                        label={t("orchestrator")}
                        InputLabelProps={{
                          shrink: !!watch("orchestratorId"),
                        }}
                        options={orchestrators ?? []}
                        optionLabel="name"
                        value={orchestrators?.find((item) => (watch("orchestratorId")) === (item?.id))}
                        onChange={(e) => setValue("orchestratorId", e?.id)}
                        readOnly={disableFields}
                    />
                </Grid>
                <Grid item xs={5}>
                  <CustomAutoComplete
                        fullWidth
                        InputProps={{
                          readOnly: disableFields,
                        }}
                        id="processId"
                        label={t("Process")}
                        InputLabelProps={{
                          shrink: !!watch("processId"),
                        }}
                        options={processes ?? []}
                        optionLabel="processDescription.processDisplayName"
                        value={processes?.find((item) => (watch("processId")) === (item?.id))}
                        onChange={(e) => setValue("processId", e?.id)}
                        readOnly={disableFields}
                    />
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item xs={5}>
                  <CustomAutoComplete
                        fullWidth
                        InputProps={{
                          readOnly: disableFields,
                        }}
                        id="trigger"
                        label={t("Trigger")}
                        InputLabelProps={{
                          shrink: !!watch("trigger"),
                        }}
                        options={triggers ?? []}
                        value={watch("trigger")}
                        onChange={(e) => setValue("trigger", e)}
                        error={errors?.trigger?.message}
                        readOnly={disableFields}
                    />
                </Grid>
                <Grid item xs={5}>
                  {watch("trigger") === triggers[0] && (
                    <CustomAutoComplete
                        fullWidth
                        InputProps={{
                          readOnly: disableFields,
                        }}
                        id="queueId"
                        label={t("queueSingle")}
                        InputLabelProps={{
                          shrink: !!watch("workQueueId"),
                        }}
                        options={queues ?? []}
                        optionLabel="name"
                        value={queues?.find((item) => (watch("workQueueId")) === (item?.id))}
                        onChange={(e) => setValue("workQueueId", e?.id)}
                        readOnly={disableFields}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item xs={5}>
                  <CustomAutoComplete
                        {...register("stopRequest", { required: true })}
                        fullWidth
                        InputProps={{
                          readOnly: disableFields,
                        }}
                        id="stopMode"
                        label={t("Stop mode")}
                        InputLabelProps={{
                          shrink: !!watch("orchestrationName"),
                        }}
                        options={stopModes ?? []}
                        value={watch("stopRequest")}
                        onChange={(e) => setValue("stopRequest", e)}
                        error={errors?.stopMode?.message}
                        readOnly={disableFields}
                    />
                </Grid>
                <Grid item xs={5}>
                  <CustomAutoComplete
                        fullWidth
                        InputProps={{
                          readOnly: disableFields,
                        }}
                        id="processPriority"
                        label={t("Process Priority")}
                        InputLabelProps={{
                          shrink: !!watch("processPriority"),
                        }}
                        options={processPriorities ?? []}
                        value={watch("processPriority")}
                        onChange={(e) => setValue("processPriority", e)}
                        error={errors?.processPriority?.message}
                        readOnly={disableFields}
                    />
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid container direction="row" xs={5} alignItems="center">
                  <Switch className={classes.switchLabel} checked={watch("isActive")} onClick={() => setValue("isActive", !getValues("isActive"))} disabled={disableFields} />
                  <Typography variant="subtitle1">{t("Activate Orchestration")}</Typography>
                </Grid>
              </Grid>
            </Grid>
          )
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("Cancel")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {t("next")}
          </CustomButton>
        </>
      ),
    },
    {
      content: (
        <Grid container item xs={12} direction="column" spacing={2}>
          { (mode === "edit" || mode === "add") && (
            <Grid container item xs={12} direction="column" alignItems="flex-end" justifyContent="flex-end">
              <CustomButton view="primary" onClick={handleAddSla}>
                {t("Add an sla")}
              </CustomButton>
            </Grid>
          )}
          <Grid item xs={12}>
            <SlaInfo
              classes={classes}
              handleClickOpen={() => setIsSlaFormOpen(true)}
              slas={slas}
              setSlas={setSlas}
              setValue={setValue}
              setIsViewMode={setIsViewMode}
              setSelectedSlaIndex={setSelectedSlaIndex}
              disableFields={disableFields}
            />
          </Grid>
        </Grid>
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("fleet.add.previous")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {t("next")}
          </CustomButton>
        </>
      ),
      notUseBorder: true,
      disabledTab: watch("trigger") !== triggers[0],
    },
    {
      content: (
        <Grid container item xs={12} direction="row" spacing={2}>
          <Grid item xs={6} className={clsx(classes.slaSectionContainer, classes.queueContainerSpace)}>
            <Box component={Grid} display="flex" alignItems="flex-end" height="revert !important" item xs={12} className={classes.inputField}>
              <Typography variant="subtitle2" className={classes.frequencyLabel}>{t("start.date")}</Typography>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getLocale()}
              >
                <FormControl margin="none" fullWidth>
                  <DatePicker
                        InputProps={{ endAdornment: <TodayOutlinedIcon className={classes.popupIcon} fontSize="small" /> }}
                        {...register("schedulingInfo.startDate", { required: true })}
                        value={watch("schedulingInfo.startDate")}
                        onChange={handleStartDateChange}
                        showTodayButton
                        fullWidth
                        minDate={new Date()}
                        className={classes.input}
                        format={formatDatePickerByLanguage(false)}
                        cancelLabel={t("user.button.cancel")}
                        todayLabel={t("Today")}
                        ampm={false}
                        error={errors?.startDate}
                        helperText={errors?.startDate && t("Your input is required")}
                        disabled={disableFields}
                    />
                </FormControl>
              </MuiPickersUtilsProvider>
            </Box>
            <Box component={Grid} display="flex" alignItems="flex-end" height="revert !important" item xs={12} className={classes.inputField}>
              <Typography variant="subtitle2" className={classes.frequencyLabel}>{t("At")}</Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="fit-content"
                  gridColumnGap={25}
                >
                  <KeyboardTimePicker
                    onChange={(value) => setValue("schedulingInfo.executionTime", getDateAndTime(value, "HH:mm").timePart)}
                    className={classes.input}
                    minutesStep={5}
                    value={watch("schedulingInfo.executionTime") ? parseTimeString(watch("schedulingInfo.executionTime")) : parseTimeString("00:00:00")}
                    ampm={false}
                    keyboardIcon={<AccessTimeIcon className={classes.chipIcon} />}
                    error={!!errors.to}
                    helperText={errors.to ? errors.to.message : ""}
                    onKeyDown={(e) => e.preventDefault()}
                    disabled={disableFields}
                  />
                </Box>
              </MuiPickersUtilsProvider>
            </Box>
            <Box component={Grid} height="revert !important" item xs={12} className={classes.inputField}>
              <Box display="flex" alignItems="flex-end" height="revert !important">
                <Typography variant="subtitle2" className={classes.dateLabel}>{t("Frequency")}</Typography>
                <CustomSelectField
                  options={periods}
                  optionLabel="label"
                  optionValue="value"
                  value={watch("schedulingInfo.unitType")}
                  onChange={handleUnitTypeChange}
                  variant="standard"
                  formControlClassName={classes.periodFormControl}
                  selectMenuClassName={classes.periodFormControlLabel}
                  isCustom
                  fullWidth
                  disabled={disableFields}
                />
              </Box>
            </Box>
            <Box component={Grid} display="flex" alignItems="flex-end" height="revert !important" item xs={12} className={classes.inputField}>
              <Typography variant="subtitle2" className={classes.dateLabel}>{t("Every")}</Typography>
              <CustomAutoComplete
                  onChange={(value) => setValue("schedulingInfo.frequency", value)}
                  options={targetPeriod?.frequency ?? []}
                  value={watch("schedulingInfo.frequency")}
                  className={classes.selectEmpty}
                  defaultValue={1}
                  readOnly={disableFields}
              />
              <Typography variant="subtitle2" className={classes.dateLabel}>{targetPeriod?.frequencyLabel}</Typography>
            </Box>
            { isMonthly() && (
              <Box component={Grid} display="flex" alignItems="flex-end" height="revert !important" item xs={12} className={classes.inputField}>
                <Typography variant="subtitle2" className={classes.dateLabel}>{t("day.on")}</Typography>
                <CustomSelectField
                    onChange={(e) => onDayActionChange(e.target.value)}
                    options={scheduleTypeDayOfMonth}
                    value={watch("schedulingInfo.scheduleTypeDayOfMonth")}
                    variant="standard"
                    formControlClassName={classes.selectEmpty}
                    selectMenuClassName={classes.periodFormControlLabel}
                    customOptionLabel={(row) => composeDayConditionLabel(row)}
                    isCustom
                    disabled={disableFields || !isMonthly()}
                />
              </Box>
            )}
            { (isMonthly() && dayOfStartDate >= 29 && watch("schedulingInfo.scheduleTypeDayOfMonth") === scheduleTypeDayOfMonth[0]) && (
              <Box component={Grid} display="flex" alignItems="flex-end" height="revert !important" item xs={12} className={classes.inputField}>
                <Typography variant="subtitle2" className={classes.dayDoesNotExistLabel}>{t("selected.day.not.exist.warning")}</Typography>
              </Box>
            )}
            { (isMonthly() && watch("schedulingInfo.scheduleTypeDayOfMonth") === scheduleTypeDayOfMonth[1]) && (
              <Box component={Grid} display="flex" alignItems="flex-end" height="revert !important" item xs={12} className={classes.inputField}>
                <Typography variant="subtitle2" className={classes.dayOfMonthLabel}>{t("the")}</Typography>
                <CustomSelectField
                    options={dayOrder}
                    value={dayOrder[+watch("schedulingInfo.weekOrder") - 1]}
                    onChange={(e) => setValue("schedulingInfo.weekOrder", e.target.value?.value)}
                    variant="standard"
                    formControlClassName={classes.dayOfMonthSelect}
                    selectMenuClassName={classes.periodFormControlLabel}
                    customOptionLabel={(row) => row?.label}
                    defaultValue={1}
                    isCustom
                    disabled={disableFields}
                />
                <CustomSelectField
                    options={quartzInitialDays}
                    value={quartzInitialDays.find((d) => d.id === selectedDayId)}
                    onChange={(e) => onDaySelect(e.target.value)}
                    variant="standard"
                    formControlClassName={classes.dayOfMonthSelect}
                    selectMenuClassName={classes.periodFormControlLabel}
                    customOptionLabel={(row) => t(row.fullLabel)}
                    defaultValue={1}
                    isCustom
                    disabled={disableFields}
                />
                <Typography variant="subtitle2" className={classes.dayOfMonthLabel}>{t("of.the.month")}</Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={6} className={classes.slaSectionContainer}>
            <Grid item xs={12} className={classes.daysContainer}>
              <WeekDaysCalendar
                  title="restriction.form.executionDay"
                  titleStyle={classes.executionTimeTitle}
                  allDaysLabel="restriction.form.allDays"
                  days={weekDays}
                  setDays={setWeekDays}
                  allDaysChecked={weekDays.filter((day) => day.checked).length === 7}
                  disableFields={disableFields || (watch("schedulingInfo.unitType") !== periods[0].value
                    && watch("schedulingInfo.unitType") !== periods[1].value
                    && watch("schedulingInfo.unitType") !== periods[2].value)}
              />
            </Grid>
            <Grid item xs={12} className={classes.daysContainer}>
              <DayHoursSelector
                  title="execution.range"
                  titleStyle={classes.executionTimeTitle}
                  setError={() => {}}
                  clearErrors={() => {}}
                  errors={errors}
                  fromTime={watch("schedulingInfo.allowedStartTime") || "00:00"}
                  toTime={watch("schedulingInfo.allowedEndTime") || "23:59"}
                  setFromTime={(value) => setValue("schedulingInfo.allowedStartTime", value)}
                  setToTime={(value) => setValue("schedulingInfo.allowedEndTime", value)}
                  disableFields={disableFields}
                  pattern="HH:mm"
                  minTime={watch("schedulingInfo.executionTime")}
              />
            </Grid>
          </Grid>
          <Box component={Grid} display="flex" alignItems="flex-end" height="revert !important" item xs={12} className={classes.inputField}>
            <Typography variant="subtitle2" className={classes.scheduleDescription}>{composeScheduleDescription()}</Typography>
          </Box>
        </Grid>
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("fleet.add.previous")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {t("next")}
          </CustomButton>
        </>
      ),
      disabledTab: watch("trigger") !== triggers[1],
    },
    {
      content: (
          orchestrationDataLoading ? (
            <CircularLoader height="100%" />
          ) : (
            <Grid container direction="column" alignItems="center" spacing={7}>
              <Grid container item justify="space-between">
                <Grid item xs={5}>
                  <CustomTextField
                        fullWidth
                        InputProps={{
                          readOnly: disableFields,
                        }}
                        id="resourceGroupName"
                        label={t("Name")}
                        InputLabelProps={{
                          shrink: !!watch("resourceGroupName"),
                        }}
                        autoFocus
                        {...register("resourceGroupName", {
                          required: {
                            value: true,
                            message: t("queue.management.form.validation.required"),
                          },
                        })}
                        error={!!errors?.resourceGroupName?.message}
                        helperText={errors?.resourceGroupName?.message}
                        onChange={(e) => setValue("resourceGroupName", e.target.value)}
                    />
                </Grid>
                <Grid item xs={5}>
                  <CustomAutoComplete
                        multiple
                        fullWidth
                        InputProps={{
                          readOnly: disableFields,
                        }}
                        id="resources"
                        label={t("Resources")}
                        options={processesResources ?? []}
                        value={processesResources?.filter((item) => watch("resouceGroupIds")?.includes(item?.id))}
                        optionLabel="resourceDisplayName"
                        onChange={(e) => setValue("resouceGroupIds", e.map((item) => item.id))}
                        error={errors?.resouceGroupIds?.message}
                        readOnly={disableFields}
                    />
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid container direction="row" xs={6} alignItems="center" className={classes.switchBox}>
                  <Grid item xs={2}>
                    <Switch
                      checked={useResourceSpecificCredentials}
                      onClick={(e) => setUseResourceSpecificCredentials(e.target.checked)}
                      disabled={disableFields}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography className={classes.switchLabel}>
                      {t("Use resource specific credentials")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <CustomAutoComplete
                        fullWidth
                        InputProps={{
                          readOnly: disableFields,
                        }}
                        id="credentials"
                        label={t("resource.credentials")}
                        options={resourceCredentials ?? []}
                        value={resourceCredentials?.find((item) => watch("resourceCredentialId") === item?.id)}
                        optionLabel="name"
                        onChange={(e) => setValue("resourceCredentialId", e?.id)}
                        error={errors?.credentials?.message}
                        readOnly={disableFields || useResourceSpecificCredentials}
                        disabled={useResourceSpecificCredentials}
                    />
                </Grid>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item xs={3}>
                  <CustomTextField
                        fullWidth
                        InputProps={{
                          readOnly: disableFields,
                          inputProps: { min: 1, max: watch("resouceGroupIds")?.length },
                        }}
                        id="maxConccurentSession"
                        label={t("max.conccurent.session")}
                        autoFocus
                        {...register("maxConccurentSession", {
                          required: {
                            value: true,
                            message: t("queue.management.form.validation.required"),
                          },
                        })}
                        error={!!errors?.maxConccurentSession?.message}
                        helperText={errors?.maxConccurentSession?.message}
                        onChange={(e) => setValue("maxConccurentSession", e.target.value)}
                        type="number"
                    />
                </Grid>
                <Grid item xs={8}>
                  <CustomAutoComplete
                        fullWidth
                        InputProps={{
                          readOnly: disableFields,
                        }}
                        id="maxSessionsAction"
                        label={t("max.sessions.action")}
                        InputLabelProps={{
                          shrink: !!watch("maxSessionsAction"),
                        }}
                        options={maxSessionsActions ?? []}
                        value={watch("maxSessionsAction")}
                        onChange={(e) => setValue("maxSessionsAction", e)}
                        error={errors?.maxSessionsAction?.message}
                        readOnly={disableFields}
                    />
                </Grid>
              </Grid>
              {getValues("maxSessionsAction") === maxSessionsActions[2] && (
                <Grid container item justify="space-between">
                  <Grid item xs={3}>
                    <CustomTextField
                          fullWidth
                          InputProps={{
                            readOnly: disableFields,
                            inputProps: { min: 1, max: watch("resouceGroupIds")?.length },
                          }}
                          id="timeout"
                          label={t("timeout.in.seconds")}
                          autoFocus
                          {...register("timeout", {
                            required: {
                              value: true,
                              message: t("queue.management.form.validation.required"),
                            },
                          })}
                          error={!!errors?.timeout?.message}
                          helperText={errors?.timeout?.message}
                          onChange={(e) => setValue("timeout", e.target.value)}
                          type="number"
                      />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )
      ),
      actions: (
        <>
          <CustomButton view="cancelModal" onClick={handleBack}>
            {t("Cancel")}
          </CustomButton>
          <CustomButton view="primary" onClick={handleSaveClick}>
            {idOrchestration ? t("Update") : t("Save")}
          </CustomButton>
        </>
      ),
    },
  ];

  return (
    <>
      <DialogWithTabs
          open={props?.open}
          entityBaseUrl={INTELLIGENT_ORCHESTRATORS_BASE_URL}
          moduleName="intelligentOrchestration"
          tabs={enabledTabs}
          disableFields={disableFields}
          editCondition
          tabsContents={tabsContents.filter((item) => !item.disabledTab)}
          idItem={idOrchestration}
          handleChange={handleChange}
          activeStep={activeStep}
          height={disableFields ? 480 : 500}
          width={(activeStep === 1 && watch("trigger") === triggers[0]) ? 1200 : 800}
        />
      {openMsgConfirm && (
      <ConfirmMessage
            message={idOrchestration ? t("orchestration.update.confirmMsg") : t("orchestration.save.confirmMsg")}
            openStart={openMsgConfirm}
            onCancel={cancelConfirm}
            onConfirm={confirmSave}
            buttonConfirm={idOrchestration ? t("update") : t("save")}
            buttonCancel={t("cancel")}
            isLoading={isLoading}
          />
        )}
      {openMsgCancel && (
      <ConfirmMessage
            message={t("orchestration.update.discard")}
            openStart={openMsgCancel}
            onCancel={handleRejectCancelForm}
            onConfirm={handleAcceptCancelForm}
            buttonConfirm={t("discard")}
            buttonCancel={t("cancel")}
            isLoading={false}
          />
        )}
      {isSlaFormOpen && (
        <SlaForm
          open={isSlaFormOpen}
          handleClose={() => setIsSlaFormOpen(false)}
          classes={classes}
          register={register}
          getValues={getValues}
          setValues={setValue}
          errors={[]}
          handleCloseDialog={() => setIsSlaFormOpen(false)}
          setIsViewMode={setIsViewMode}
          isViewMode={isViewMode}
          sla={slas[selectedSlaIndex]}
          setSlas={setSlas}
          selectedSlaIndex={selectedSlaIndex}
        />
      )}
    </>
  );
}
