import React from "react";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import SettingsIcon from "@material-ui/icons/Settings";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import DoneIcon from "@material-ui/icons/Done";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  fetchOrchestrator,
  fetchOrchestratorConfigState,
  initOrchestratorDatabase,
  initOrchestratorSynchro,
  resetOrchestratorSynchro,
} from "../../../../../redux/actions/services";
import CircularLoader from "../../../../../components/Loaders/CircularLoader";
import CustomButton from "../../../../../components/CustomButton";
import useStyles from "../style";

function ConfigurationDrawer({ open, setOpen, orchestratorId }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [databaseConfig, setDatabaseConfig] = React.useState({
    disabled: true,
    done: false,
    loading: false,
  });
  const [synchroConfig, setSynchroConfig] = React.useState({
    disabled: true,
    done: false,
    loading: false,
  });
  const [loading, setLoading] = React.useState(false);
  const [orchestrator, setOrchestrator] = React.useState({});

  const fetch = () => {
    setLoading(true);
    dispatch(fetchOrchestratorConfigState(orchestratorId)).then((res) => {
      if (res.status === 200) {
        setDatabaseConfig({
          disabled: res.data.databaseConfigured,
          done: res.data.databaseConfigured,
        });
        setSynchroConfig({
          disabled: res.data.synchroInitialized || !res.data.databaseConfigured,
          done: res.data.synchroInitialized,
        });
      }
      dispatch(fetchOrchestrator(orchestratorId, (res) => {
        if (res.data) {
          setOrchestrator(res.data);
        }
      }));
      setLoading(false);
    });
  };

  React.useEffect(() => {
    if (open) fetch();
  }, [open]);

  const initDatabase = () => {
    setDatabaseConfig({ ...databaseConfig, loading: true });
    dispatch(initOrchestratorDatabase(orchestratorId)).then((res) => {
      if (res.status === 200 && res.data) {
        fetch();
      }
      setDatabaseConfig({ ...databaseConfig, loading: false });
    });
  };

  const initSynchro = () => {
    setSynchroConfig({ ...synchroConfig, loading: true });
    dispatch(initOrchestratorSynchro(orchestratorId)).then((res) => {
      if (res.status === 200 && res.data) {
        fetch();
      }
      setSynchroConfig({ ...synchroConfig, loading: false });
    });
  };

  const resetSynchro = () => {
    setSynchroConfig({ ...synchroConfig, loading: true });
    dispatch(resetOrchestratorSynchro(orchestratorId)).then((res) => {
      if (res.status === 200 && res.data) {
        fetch();
      }
      setSynchroConfig({ ...synchroConfig, loading: false });
    });
  };

  if (!open) return null;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        className: classes.drawerPaper,
      }}
    >
      <Box py={2} display="flex" alignItems="center" gridColumnGap={10}>
        <SettingsIcon />
        <Typography variant="h6">{t("Orchestrator configuration")}</Typography>
      </Box>
      {loading ? (
        <CircularLoader />
      ) : (
        <>
          {orchestrator?.orchestratorType !== "uipath" && (
            <Box
              className={clsx(
                classes.configBox,
                databaseConfig.disabled ? classes.disabledConfigBox : null
              )}
            >
              <Typography variant="subtitle1" color="primary">
                1.
                {t("Database configuration")}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {t(
                  "SmartRoby will add columns and create views and triggers on your orchestrator database"
                )}
              </Typography>
              {databaseConfig.done ? (
                <Box textAlign="center">
                  <DoneIcon style={{ color: "green" }} fontSize="large" />
                </Box>
              ) : (
                <CustomButton
                  view="primary"
                  disabled={databaseConfig.disabled || databaseConfig.loading}
                  onClick={initDatabase}
                >
                  {!databaseConfig.loading && t("sync.Execute")}
                  {databaseConfig.loading && (
                    <CircularProgress size={20} className={classes.circularProgress} />
                  )}
                </CustomButton>
              )}
            </Box>)}
          <Box
            className={clsx(
              classes.configBox,
              synchroConfig.disabled ? classes.disabledConfigBox : null
            )}
          >
            <Typography variant="subtitle1" color="primary">
              2.
              {t("Orchestrator/SmartRoby synchronization")}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {t(
                "Initialize connectors to synchronize data between your orchestrator and SmartRoby"
              )}
            </Typography>
            {synchroConfig.done ? (
              <>
                <Box textAlign="center">
                  <DoneIcon style={{ color: "green" }} fontSize="large" />
                </Box>
                <CustomButton
                  view="primary"
                  disabled={synchroConfig.loading}
                  onClick={resetSynchro}
                >
                  {!synchroConfig?.loading && t("Reset")}
                  {synchroConfig?.loading && (
                    <CircularProgress size={20} className={classes.circularProgress} />
                  )}
                </CustomButton>
              </>
            ) : (
              <CustomButton
                view="primary"
                disabled={synchroConfig.disabled || synchroConfig.loading}
                onClick={initSynchro}
              >
                {!synchroConfig?.loading && t("sync.Initialize")}
                {synchroConfig?.loading && (
                  <CircularProgress size={20} className={classes.circularProgress} />
                )}
              </CustomButton>
            )}
          </Box>
        </>
      )}
    </Drawer>
  );
}

export default ConfigurationDrawer;
