import React from "react";
import { useTranslation } from "react-i18next";
import {
  formatDateByLanguage,
} from "util";
import CustomTableCell from "../../../../../components/TableComponents/CustomTableCell";
import ProcessIconName from "../../../../../components/TableComponents/ProcessIconName";
import { secondsToTime } from "util/index";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../../style";
import {
  composeScheduleDescription,
  quartzInitialDays, responseTimeCalculationOptions, triggers
} from "pages/IntelligentOrchestration/util";

const ExecutionRequestRow = ({
  row,
  isHistorical,
  processName,
}) => {
  const { t } = useTranslation()
  const classes = useStyles();
  const getDateDifference = (s) => {
    if (!s) return secondsToTime(0, t);
    return secondsToTime(s, t);
  };
  const renderCustomTriggerDetails = (row) => {
    const { trigger } = row;
    if (trigger === triggers[0]) {
      return (
        <Grid
          container
          alignItems="center"
          wrap="nowrap"
          className={classes.slaDescGrid}
        >
          <Grid item>
            <Typography variant="subtitle2" className={classes.cellTitle} component="span">
              {`${t("using.sla")}: `}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" className={classes.cellTitleQueueName} component="span">
              {row.slaName}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" className={classes.cellTitle} component="span">
              {`${t("with.filter")} : `}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" className={classes.cellTitleQueueName} component="span">
              {row.slaTagFilter}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" className={classes.cellTitle} component="span" wrap="wrap">
              {row?.slaResponseTime === responseTimeCalculationOptions[0]
                ? t("creation.time.calc.description")
                : t("last_update.time.calc.description")}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    if (trigger === triggers[1]) {
      const selectedDays = row?.allowedDays ? quartzInitialDays.map((day) => ({ ...day, checked: row?.allowedDays?.includes(day.id) })) : quartzInitialDays;
      const selectedDayId = quartzInitialDays.find((d) => d.checked)?.id;
      const startDay = new Date(row?.requestDate).getDay();
      return (
        <Grid alignItems="center">
          <Typography variant="subtitle2" className={classes.cellTitle}>
            {composeScheduleDescription(row?.frequency, row?.unitType, selectedDays, row?.executionTime, t, row?.weekOrder, selectedDayId, startDay, row?.scheduleTypeDayOfMonth, true)}
          </Typography>
        </Grid>
      );
    }
    return "---"
  }
    return (
      <>
        <CustomTableCell>
          <ProcessIconName
            processName={processName}
          />
        </CustomTableCell>
        <CustomTableCell text={row?.resourceName ? row?.resourceName : "---"} />
        <CustomTableCell
          text={
            row?.requestDate
              ? formatDateByLanguage(row?.requestDate)
              : "---"
          }
        />
        <CustomTableCell text={getDateDifference(row?.estimatedDuration)} />
        <CustomTableCell text={getDateDifference(row?.maxWaitTime)} />
        <CustomTableCell text={t(row?.trigger) || "---"} />
        <CustomTableCell>
          {renderCustomTriggerDetails(row)}
        </CustomTableCell>
        {isHistorical && (
          <CustomTableCell text={row?.origineOfStop || "---"} />
        )}
        {isHistorical && (
          <CustomTableCell text={row?.archivedAt ? formatDateByLanguage(row?.archivedAt) : "---"} />
        )}
      </>
  );
};

export default ExecutionRequestRow;
